import React, {useEffect, useMemo, useState} from "react";
import deduceType from "../../utils/InputTypeDeducer";
import {Button} from "antd";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import {isNotEmpty} from "../../utils/ObjectUtils";
import {DisplayModes} from "../MainView";
import {BASE_URL} from "../../App";
import {trim} from 'lodash';

interface Context extends GlobalComponentContext {
    value: string;
    isSimplifiedDisplayMode: boolean;
    imposedType: string;
}

const SearchableOutputValueItem = ({value, setGlobalState, globalState, isSimplifiedDisplayMode, imposedType}: Context) => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (imposedType && imposedType !== 'None') {
            setShowButton(true);
        }
        if (value && imposedType !== 'None') {
            const type = deduceType(value);
            if (type) {
                setShowButton(true);
            }
        }
    }, [value])

    const onClick = (val) => {
        if (isSimplifiedDisplayMode) {
            window.open(`${BASE_URL}?input=${trim(val)}&type=${imposedType || deduceType(val)}`, '_blank')
            return;
        }

        // @ts-ignore
        if (globalState.outputContainerRef && globalState.outputContainerRef.current) {
            // @ts-ignore
            globalState.outputContainerRef.current.scrollTop = 0;
        }

        setGlobalState({
            ...globalState,
            searchInputExternal: trim(val),
            filters: {...globalState.filters, imposedType: imposedType}
        });
    }


    return (
        <>
            {!showButton && value && <span dangerouslySetInnerHTML={{__html: value}}/>}
            {setGlobalState && showButton && value.split('|').map((v, i) =>
                <> <Button style={{padding: 0, height: 'auto'}} type="link" onClick={() => onClick(v)}>{v}</Button> {value.split('|').length - 1 !== i ? '|' : '' } </>
            )}
        </>
    );
}

const SearchableOutputValue = ({content, setGlobalState, globalState, imposedType}) => {
    const [modifiedContent, setModifiedContent] = useState(undefined);
    const isSimplifiedDisplayMode = useMemo(() => {
        return DisplayModes.SIMPLIFIED === globalState.displayMode
    }, [globalState.displayMode])

    useEffect(() => {
        if (content && typeof content === 'string') {
            const regExp = new RegExp('(\\\\n|<br\\/>|<br>|\\n)');
            setModifiedContent(content.split(regExp).filter(v => !regExp.test(v)).filter(v => v !== '\n').map(entry => {
                return <>
                    <SearchableOutputValueItem isSimplifiedDisplayMode={isSimplifiedDisplayMode} value={entry}
                                               setGlobalState={setGlobalState} globalState={globalState}
                                               imposedType={imposedType}/>
                    {entry && <br/>}
                </>;
            }));
        } else {
            setModifiedContent(content);
        }
    }, [content]);
    return (<>
        {modifiedContent && modifiedContent === content &&
        <div dangerouslySetInnerHTML={{__html: modifiedContent}}></div>}
        {isNotEmpty(modifiedContent) && modifiedContent !== content &&
        <div>{modifiedContent.map(line => (<>{line}  </>))}</div>}
    </>);
}

export default SearchableOutputValue;
