import {Modal} from 'antd';
import React from 'react';
import styled from "styled-components";
import {WidgetListItemWrapper} from "./WidgetListItem";

const Wrapper = styled.div`
  box-shadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0.8rem;

  strong {
    margin-right: 5px;
  }

`;

const WidgetMissingAuthModal = ({widget, showInfo, setShowInfo, validSetup, setShowSettings}) => {
    const modalHeader = (
        <WidgetListItemWrapper validSetup={validSetup} style={{backgroundColor: '#d2e1da'}}>
            <div className="status"/>
            <div className="title">
                {widget.display}
            </div>
        </WidgetListItemWrapper>
    );

    return (
        <Modal
            key={widget.id}
            id={widget.id}
            width="420px"
            title={modalHeader}
            visible={showInfo}
            maskClosable
            destroyOnClose
            // footer={null}
            wrapClassName="widget-info-modal"
            onCancel={() => setShowInfo(false)}
            okText={'Configure'}
            onOk={() => {
                window.location.hash = encodeURIComponent(widget.id);
                setShowSettings(true);
            }}
        >
            <Wrapper>
                Cannot run this widget because it lacks authentication details. To setup this widget click on configure.
            </Wrapper>
        </Modal>
    );
};

export default WidgetMissingAuthModal;
