import PropTypes from 'prop-types';
import React from 'react';

const WidgetError = ({ title, description }) => {
    let descriptionRender = description;
    if (typeof description === 'object') {
        descriptionRender = JSON.stringify(description);
    }

    return (
        <div style={{ padding: '1rem', backgroundColor: '#fff' }}>
            <h3>{title}</h3>
            <p>{descriptionRender}</p>
        </div>
    );
};

WidgetError.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

WidgetError.defaultProps = {
    description: ''
};

export default WidgetError;
