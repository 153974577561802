import React, {useEffect, useMemo, useRef, useState} from 'react';
import ExecutionInputs from "../ExecutionInputs";
import Widgets from "../Widgets";
import Links from "../Links";
import WidgetOutput from "../WidgetOutput";
import axios from 'axios';
import WidgetInfo from "../../models/WidgetInfo";
import LinkInfo from "../../models/LinkInfo";
import GlobalState from "../../models/GlobalState";
import styled from "styled-components";
import {Button, Col, Input, Progress, Row, Spin} from "antd";
import {ArrowUpOutlined, LoadingOutlined, QuestionOutlined, SettingOutlined} from "@ant-design/icons";
import WidgetConfigurationModal from "../WidgetConfigurationModal";
import WidgetsSettingsList from "../WidgetSettingsList";
import WidgetItemInfo from "../../models/WidgetItemInfo";
import LinkItemInfo from "../../models/LinkItemInfo";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons/lib";
import {debounce} from "lodash";
import lunr from 'lunr';
import {flatten, isEmpty, isNotEmpty} from "../../utils/ObjectUtils";
import {ProgressInfo} from "../../models/ProgressInfo";
import {BASE_URL, sendNewLinksConfiguration, sendNewTypesInfo, sendWidgetSettingsConfiguration, triggerOnExternalCloseEvent} from "../../App";
import {filterResultsByContext} from "../../utils/FilteringUtil";
import {shouldIndexKey} from "../../utils/WidgetOutputUtil";
import {useNavigate} from 'react-router-dom'

const MainViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow: hidden;
  height: 100%;

  .widgets-wrapper {
    display: flex;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }

  .widgets-wrapper.simplified {
    div:nth-child(n) {
      padding: 0;
      margin: 0;
    }
  }

  .widgets-container {
    margin-right: 10px;
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
  }

  .ant-input-xl {
    font-size: 18px !important;

    > div, .ant-select-selector {
      font-size: 16px !important;
      height: 44px;
    }
  }

  .ant-progress-text {
    color: #167783
  }

`;

export enum DisplayModes {
    NORMAL = 'NORMAL',
    SIMPLIFIED = 'SIMPLIFIED'

}

const MainView = ({setForceRefresh, setShowSettings, showSettings, controlledFocus, setControlledFocus}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [widgets, setWidgets] = useState<WidgetInfo>();
    const [widgetListKey, setWidgetListKey] = useState(0);
    const [links, setLinks] = useState<LinkInfo>();
    const [types, setTypes] = useState(new Set<string>());
    const [visibleOutputs, setVisibleOutputs] = useState([]);
    const [outputSearchIndex, setOutputSearchIndex] = useState();
    const [hasAutorunWidgets, setHasAutorunWidgets] = useState(true);
    const [maximizeOutputs, setMaximizeOutputs] = useState(false);
    const [typesInfo, setTypesInfo] = useState({});
    const [globalState, setGlobalState] = useState<GlobalState>({
        searchInput: localStorage.getItem('searchInput'),
        searchInputExternal: '',
        searchUtility: '',
        multilineSearch: false,
        widgetOutputs: [],
        filters: {
            type: localStorage.getItem('type')
        },
        widgetConfiguration: undefined,
        widgetAutorunConfig: {},
        stopWidgetExecution: false,
        executionUrl: undefined,
        showExecutionUrl: false,
        manualTypeChange: false,
        displayMode: DisplayModes.NORMAL,
        outputContainerRef: undefined
    });

    useEffect(() => {
        if (globalState) {
            localStorage.setItem('searchInput', globalState.searchInput);
            localStorage.setItem('type', globalState.filters.type);
        }
    }, [globalState.searchInput, globalState.filters.type])

    useEffect(() => {
        if (!typesInfo) {
            return;
        }
        if (isEmpty(globalState.filters.type) && !globalState.searchInputExternal) {
            setGlobalState(({
                ...globalState,
                searchInput: typesInfo['email-address']?.default,
                filters: {type: 'email-address'}
            }))
            return;
        }

        if (!globalState.filters.type) {
            return;
        }
        if (globalState.searchInput === typesInfo[globalState.filters.type]) {
            return;
        }
        // @ts-ignore
        if (Object.values(typesInfo).find(v => v.default === globalState.searchInput) || (isEmpty(globalState.searchInput) && globalState.manualTypeChange)) {
            if (!typesInfo[globalState.filters.type] || !typesInfo[globalState.filters.type].default) {
                return;
            }
            setGlobalState(({...globalState, searchInput: typesInfo[globalState.filters.type]?.default, manualTypeChange: false}))
        }
    }, [globalState.filters.type, globalState.searchInput, typesInfo])

    const [widgetExecutionProgress, setWidgetExecutionProgress] = useState(undefined as ProgressInfo);

    const isSimplifiedDisplayMode = useMemo(() => {
        return DisplayModes.SIMPLIFIED === globalState.displayMode
    }, [globalState.displayMode])

    useEffect(() => {
        if (!widgetExecutionProgress) {
            return;
        }
        if (widgetExecutionProgress.percent === 100 || (widgetExecutionProgress.currentStep &&
            widgetExecutionProgress.totalSteps &&
            widgetExecutionProgress.currentStep === widgetExecutionProgress.totalSteps)
        ) {
            setTimeout(() => {
                setWidgetExecutionProgress({
                    totalSteps: 0,
                    currentStep: 0,
                    percent: 0
                });
            }, 5000);
        }
    }, [widgetExecutionProgress]);

    function addTypes(items: (WidgetItemInfo | LinkItemInfo)[], target: Set<string>) {
        items.filter(i => i.types)
            .forEach(i => i.types.forEach(target.add, target));
    }

    const navigate = useNavigate()

    function getExecutionUrl() {
        let savedUrl = localStorage.getItem("EXECUTION_URL");
        if (savedUrl === 'https://4zzjmlazef.execute-api.us-east-1.amazonaws.com/prod1/') {
            savedUrl = process.env.REACT_APP_WIDGET_CONFIG_URL;
            localStorage.setItem('EXECUTION_URL', savedUrl)
        }
        return  savedUrl || process.env.REACT_APP_WIDGET_CONFIG_URL;
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const input = queryParams.get('input');
        const type = queryParams.get('type');
        const displayMode = queryParams.get('displayMode');
        const showSettingsParam = queryParams.get('showSettings');
        const prefill = queryParams.get('prefill');

        setTimeout(() => {
            navigate('/', {replace: true})
        }, 2000)
        if (isNotEmpty(input)) {
            setGlobalState({
                ...globalState,
                widgetOutputs: [...globalState.widgetOutputs.map(wo => ({...wo, old: true}))],
            });
        }

        let config = globalState.widgetConfiguration;
        if (setGlobalState) {
            const savedSettings = localStorage.getItem('WIDGET_SETTINGS');
            if (savedSettings) {
                config = JSON.parse(savedSettings);
            } else {
                config = JSON.parse(`${process.env.REACT_APP_DEFAULT_WIDGET_CONFIG}`);
                localStorage.setItem('WIDGET_SETTINGS', `${process.env.REACT_APP_DEFAULT_WIDGET_CONFIG}`);
                sendWidgetSettingsConfiguration(config);
            }

            const autorunSavedSettings = localStorage.getItem('WIDGET_AUTORUN_SETTINGS');
            const outputs = localStorage.getItem("WIDGET_OUTPUTS") || '[]';
            const executionUrl = getExecutionUrl();
            setGlobalState({
                ...globalState,
                searchInput: input || globalState.searchInput,
                filters: {...globalState.filters, type: type || globalState.filters.type},
                widgetConfiguration: config,
                widgetOutputs: isNotEmpty(input) && isEmpty(prefill) ? [...JSON.parse(outputs).map(wo => ({
                    ...wo,
                    old: true
                }))] : JSON.parse(outputs),
                executionUrl: executionUrl,
                widgetAutorunConfig: JSON.parse(autorunSavedSettings),
                displayMode: isNotEmpty(displayMode) ? displayMode : DisplayModes.NORMAL
            });
            if (!showSettings) {
                setShowSettings(showSettingsParam === 'true');
            }
        }
        if (!globalState.executionUrl) {
            return;
        }
        axios.get(`${globalState.executionUrl}${process.env.REACT_APP_WIDGET_SOURCE}`)
            .then(resp => {
                const data = {url: resp.data.url, items: resp.data.items.filter(w => w.provider !== 'Spiderfoot')};
                if (config && Object.keys(config).length > 0) {
                    data?.items
                        .forEach(w => {
                            let widgetConfig = config.find(wc => wc.name === w.name);
                            if (!widgetConfig) {
                                widgetConfig = {
                                    name: w.name,
                                    fields: w.authorization.fields
                                }
                                const updatedConfig = [...config, widgetConfig]
                                setGlobalState({...globalState, widgetConfiguration: updatedConfig});
                                localStorage.setItem('WIDGET_SETTINGS', JSON.stringify(updatedConfig));
                                sendWidgetSettingsConfiguration(updatedConfig);
                            }
                            w.fields = widgetConfig?.fields;
                            w.fields = w.fields?.map(f => {
                                const af = w.authorization.fields.find(af => af.key === f.key);
                                return {
                                    ...af,
                                    ...f
                                }
                            })
                        });
                }
                setWidgets(data);
            });
        axios.get(`${globalState.executionUrl}${process.env.REACT_APP_LINKS_SOURCE}`)
            .then(resp => {
                setLinks({
                    url: resp.data.url,
                    items: resp.data.items,
                    typesInfo: resp.data.typesInfo
                })
                setTypesInfo(resp.data.typesInfo)
                let savedConfig = JSON.parse(localStorage.getItem("LINKS_CONFIG") || '{}')
                const availableLinks = resp.data.items.map(i => i.id);
                Object.keys(savedConfig).forEach(type => {
                    savedConfig[type] = savedConfig[type].filter(l => availableLinks.includes(l.id))
                    savedConfig[type] = savedConfig[type].map(l => ({
                        ...resp.data.items.filter(i => i.id === l.id)[0],
                        types: undefined,
                        autorun: l.autorun
                    }))
                })

                localStorage.setItem("LINKS_CONFIG", JSON.stringify(savedConfig));
                sendNewLinksConfiguration(savedConfig);
                sendNewTypesInfo(resp.data.typesInfo)
            });

    }, [globalState.executionUrl]);

    useEffect(() => {
        if (globalState.widgetConfiguration) {
            const config = globalState.widgetConfiguration;
            const executionUrl = localStorage.getItem("EXECUTION_URL") || process.env.REACT_APP_WIDGET_CONFIG_URL;
            axios.get(`${executionUrl}${process.env.REACT_APP_WIDGET_SOURCE}`)
                .then(resp => {
                    const data = {url: resp.data.url, items: resp.data.items.filter(w => w.provider !== 'Spiderfoot')};
                    if (config && Object.keys(config).length > 0) {
                        data?.items
                            .forEach(w => {
                                let widgetConfig = config.find(wc => wc.name === w.name);
                                if (!widgetConfig) {
                                    widgetConfig = {
                                        name: w.name,
                                        fields: w.authorization.fields
                                    }
                                    const updatedConfig = [...config, widgetConfig]
                                    setGlobalState({...globalState, widgetConfiguration: updatedConfig});
                                    localStorage.setItem('WIDGET_SETTINGS', JSON.stringify(updatedConfig));
                                    sendWidgetSettingsConfiguration(updatedConfig);
                                }
                                w.fields = widgetConfig?.fields;
                                w.fields = w.fields?.map(f => {
                                    const af = w.authorization.fields.find(af => af.key === f.key);
                                    return {
                                        ...af,
                                        ...f
                                    }
                                })
                            });
                    }
                    setWidgets(data);
                });
        }
    }, [globalState, globalState.widgetConfiguration])


    useEffect(() => {
        let autorunConfig = globalState.widgetAutorunConfig;
        if (autorunConfig && widgets && widgets.items) {
            widgets.items.forEach(w => {
                w.autorun = (autorunConfig[globalState.filters.type] || []).find(wc => wc.name === w.name)?.autorun || false;
            });
        }

    }, [globalState.executionUrl, globalState.widgetConfiguration, globalState.widgetAutorunConfig, widgets])

    useEffect(() => {
        if (widgets && widgets.items && globalState.widgetConfiguration && Object.keys(globalState.widgetConfiguration).length > 0) {
            widgets?.items?.forEach(w => {
                let autorunConf = (globalState.widgetAutorunConfig ? (globalState.widgetAutorunConfig[globalState.filters.type] || []) : []).find(wc => wc.name === w.name);
                w.autorun = autorunConf ? autorunConf.autorun : false;
                w.fields = globalState.widgetConfiguration.find(wc => wc.name === w.name)?.fields;
                if (w.fields) {
                    // @ts-ignore
                    w.fields = w.fields.map(f => {
                        // @ts-ignore
                        const af = w.authorization.fields.find(af => af.key === f.key);
                        return {
                            ...af,
                            ...f
                        }
                    })
                }
            });
            // @ts-ignore
            setWidgets(({...widgets}));
        }
    }, [globalState.widgetConfiguration, globalState.widgetAutorunConfig, globalState.filters.type])

    useEffect(() => {
        const newTypes = new Set<string>();
        if (widgets && widgets.items) {
            addTypes(widgets.items, newTypes);
        }
        if (links && widgets && widgets.items) {
            addTypes(links.items, newTypes);
        }

        setTypes(new Set(['any', ...new Set([...newTypes].sort())]));
    }, [widgets, links])

    useEffect(() => {
        setVisibleOutputs(globalState.widgetOutputs);
        const flatOutputs = globalState.widgetOutputs.map(o => flatten(o)).map(o => {
            const normalizedObject = {};
            Object.entries(o)
                .filter(([key]) => shouldIndexKey(key))
                .forEach(([key, value]) => {
                    normalizedObject[key === 'id' ? 'id' : btoa(key)] = value;
                })
            return normalizedObject;
        });
        const allPossibleKeys = new Set();
        flatOutputs.forEach(o => {
            Object.keys(o).forEach(k => {
                allPossibleKeys.add(k)
            })
        });
        setOutputSearchIndex(lunr(function () {
            allPossibleKeys.forEach(key => {
                if (key === 'id') {
                    return;
                }
                // @ts-ignore
                this.field(key, {
                    extractor: (doc = {}) => {
                        if (!doc) {
                            return undefined;
                        }
                        // @ts-ignore
                        return doc[key];
                    }
                })
            })
            // @ts-ignore
            this.ref('id');
            flatOutputs.forEach(output => {
                // @ts-ignore
                this.add(output);
            });
        }));
    }, [globalState.widgetOutputs]);

    useEffect(() => {
        if (widgets && widgets.items && isSimplifiedDisplayMode) {
            setHasAutorunWidgets(widgets.items
                .filter(i => i)
                .filter((i) => filterResultsByContext(globalState, i))
                .filter(i => i.autorun).length > 0);
        }
    }, [widgets, isSimplifiedDisplayMode, globalState]);

    const _globalState = useRef<any>();
    useEffect(() => {
        _globalState.current = globalState;
    }, [globalState])
    const onLoad = () => {
        if (!showSettings && !controlledFocus && _globalState.current?.displayMode !== DisplayModes.SIMPLIFIED) {
            setForceRefresh(Math.floor(Math.random() * 100))
        }
    }

    useEffect(() => {
        // @ts-ignore
        window.addEventListener('focus', onLoad)
        return () => window.removeEventListener('focus', onLoad)
    }, [showSettings, controlledFocus])

    const debouncedSetSearch = debounce((searchValue) => {
        if (!outputSearchIndex) {
            return;
        }
        // @ts-ignore
        const outputIds = outputSearchIndex.search(`*${searchValue}*`)
            .map(r => r.ref);
        setVisibleOutputs([...globalState.widgetOutputs.filter(o => outputIds.includes(o.id))]);
    }, 200);

    // @ts-ignore
    const debouncedSetSearchInputValue = debounce((inputValue) => {
        setGlobalState({...globalState, searchInput: inputValue, filters: {...globalState.filters, imposedType: undefined}});
    }, 1000);

    const [hideExtInstallNotif, setHideExtInstallNotif] = useState(true);
    useEffect(() => {
        setHideExtInstallNotif(localStorage.getItem('hide-ext-notif') === 'true')
    }, [])

    return (
        <MainViewWrapper className={`sk-main ${isSimplifiedDisplayMode ? 'simplified' : ''}`}>
            <div>
                <Row key={globalState.searchInputExternal} justify={"center"}>
                    <Col span={isSimplifiedDisplayMode ? 0 : 12}
                         style={{minHeight: isSimplifiedDisplayMode ? 100 : (showSettings ? 95 : 172)}}>
                        {!isSimplifiedDisplayMode && <span style={{display: `${showSettings ? 'none' : ''}`}}>
                            <ExecutionInputs setGlobalState={setGlobalState} globalState={globalState} types={types}
                                             typesInfo={typesInfo} debouncedSetSearchInputValue={debouncedSetSearchInputValue}/>
                        </span>}
                    </Col>
                    <Col span={isSimplifiedDisplayMode ? 24 : 12}>
                        <div style={{backgroundColor: isSimplifiedDisplayMode ? "white" : "inherit"}}>
                            <img src="/logo.png" className={`sk-logo ${isSimplifiedDisplayMode ? 'simplified' : ''}`}
                                 onClick={() => {
                                     window.open('https://www.vortimo.com/', '_blank');
                                 }}
                                 alt="vortimo-logo"/>
                            {!isSimplifiedDisplayMode && <div style={{
                                position: 'absolute',
                                left: 90,
                                top: 65,
                                display: "flex",
                                justifyContent: 'space-between',
                                width: 'calc(100% - 96px)'
                            }}>
                                <div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{
                                        border: '1px solid black',
                                        backgroundColor: '#c9dbd7',
                                        textAlign: 'center',
                                        borderRadius: 5,
                                        padding: 5,
                                        marginLeft: 15,
                                        cursor: "pointer",
                                        width: 180,
                                        lineHeight: 1
                                    }} onClick={() => window.open('https://chrome.google.com/webstore/detail/vortimo-osint-tool/mnakbpdnkedaegeiaoakkjafhoidklnf', '_blank')}>
                                        Get the <strong>OSINT-tool</strong><br/> Chrome extension
                                    </div>
                                </div>
                            </div>}
                            <span style={{position: 'absolute', right: 5, top: 0}}>
                                <Button type="text" size='large'
                                        icon={showSettings ? <CloseOutlined style={{fontSize: "32px"}}/> :
                                            <SettingOutlined style={{fontSize: isSimplifiedDisplayMode ? "20px" : "32px"}}/>}
                                        onClick={() => {
                                            if (isSimplifiedDisplayMode) {
                                                window.open(`${BASE_URL}?input=${globalState.searchInput}&type=${globalState.filters.type}&prefill=true&showSettings=true`, '_blank')
                                                return;
                                            }
                                            window.location.hash = '';
                                            setShowSettings(!showSettings)
                                            if (showSettings) {
                                                setForceRefresh(Math.floor(Math.random() * 100))
                                            }
                                        }}/>
                                {isSimplifiedDisplayMode &&
                                    <Button type="text" size='large'
                                            icon={<ArrowUpOutlined style={{fontSize: "20px"}}/>}
                                            onClick={() => {
                                                triggerOnExternalCloseEvent();
                                                window.open(`${BASE_URL}?input=${globalState.searchInput}&type=${globalState.filters.type}&prefill=true`, '_blank');
                                            }}/>}
                                {!isSimplifiedDisplayMode &&
                                    <Button type="text" size='large'
                                            icon={<QuestionOutlined style={{fontSize: "32px"}}/>}
                                            onClick={() => {
                                                window.open('https://www.vortimo.com/osint-tool-extension/', '_blank');
                                            }}/>}
                                {showSettings &&
                                    <Button type="text" size='small'
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                bottom: '-20px',
                                                width: 32,
                                                color: '#e7e7e7'
                                            }}
                                            onClick={() => {
                                                setGlobalState({
                                                    ...globalState,
                                                    showExecutionUrl: !globalState.showExecutionUrl
                                                })
                                            }}>
                                        i
                                    </Button>}
                        </span>
                            {!showSettings && <span>
                            {widgetExecutionProgress && widgetExecutionProgress.totalSteps > 0 &&
                                <span className={`sk-progress ${isSimplifiedDisplayMode ? 'simplified' : ''}`}>
                                    {widgetExecutionProgress.currentStep < widgetExecutionProgress.totalSteps && <Spin indicator={<LoadingOutlined/>}/>}
                                    <Progress style={{
                                        width: '250px',
                                        marginRight: '15px'
                                    }}
                                              strokeColor={'#167783'}
                                              percent={widgetExecutionProgress.percent}
                                              format={(percent) => `${widgetExecutionProgress.currentStep}/${widgetExecutionProgress.totalSteps}`}
                                    />
                                <Button type={"text"} icon={<CloseOutlined/>}
                                        onClick={() => {
                                            setGlobalState({...globalState, stopWidgetExecution: true})
                                        }}/>
                                </span>}

                    </span>}

                            {!showSettings &&
                                <div className={`sk-w-out-search ${isSimplifiedDisplayMode ? 'simplified' : ''}`}>
                                    <Input className="ant-input-xl" placeholder="Search in output...."
                                           prefix={<SearchOutlined/>}
                                           onChange={(e) => {
                                               debouncedSetSearch(e.target.value);
                                           }}/>
                                </div>}
                        </div>
                    </Col>
                </Row>
            </div>
            {
                !showSettings && <div className={`widgets-wrapper ${isSimplifiedDisplayMode ? 'simplified' : ''}`}>
                    <Row style={{width: '100%', overflow: 'hidden'}} justify={"center"}>
                        <Col span={isSimplifiedDisplayMode || maximizeOutputs ? 0 : 12} style={{overflow: 'hidden', height: '100%'}}>
                            <div className="widgets-container" style={{display: isSimplifiedDisplayMode ? 'none' : ''}}>
                                <Widgets key={widgetListKey} widgets={widgets} globalState={globalState}
                                         setGlobalState={setGlobalState} setShowSettings={setShowSettings}
                                         setWidgetExecutionProgress={setWidgetExecutionProgress}/>
                                <Links links={links} globalState={globalState} setGlobalState={setGlobalState}/>
                            </div>
                        </Col>
                        <Col span={isSimplifiedDisplayMode || maximizeOutputs ? 24 : 12} style={{overflow: 'hidden', height: '100%'}}>
                            <div style={{height: '100%'}}>
                                <WidgetOutput visibleOutputs={visibleOutputs} globalState={globalState}
                                              setGlobalState={setGlobalState} hasAutorunWidgets={hasAutorunWidgets}
                                              maximizeOutputs={maximizeOutputs} setMaximizeOutputs={setMaximizeOutputs}
                                              setControlledFocus={setControlledFocus} isSimplifiedDisplayMode={isSimplifiedDisplayMode}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {showSettings && widgets && widgets.items &&
                <WidgetsSettingsList widgets={widgets} setWidgets={setWidgets} globalState={globalState} setGlobalState={setGlobalState}
                                     c={globalState.widgetConfiguration} typesInfo={typesInfo}/>
            }
            <WidgetConfigurationModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}
                                      setWidgetListKey={setWidgetListKey} setConfiguredLinks
                                      globalState={globalState} setGlobalState={setGlobalState}/>
        </MainViewWrapper>);
}

export default MainView;
