import WidgetExecutionOutput from "../../../models/WidgetExecutionOutput";
import React from "react";
import {Col, Row} from "antd";
import DateTime from "./DateTime";
import styled from "styled-components";
import SearchableOutputValue from "../SearchableOutputValue";
import GlobalComponentContext from "../../../models/GlobalComponentContext";

interface Context extends GlobalComponentContext {
    data: WidgetExecutionOutput
}

const TableWrapper = styled.div`
    border: 1px solid #eee;
    background-color: #fff;
    
    .ant-col {
        padding: 5px 10px;
        text-align: left;
        box-shadow: inset 0 1px 0 0 rgb(16 22 26 / 15%);
        display: flex;
        align-items: center;
        overflow: hidden;
        
        h5 {
            font-size: 14px;
        }
    }
    
    .vs-table-col {
        background-color: #fff;
        padding: 0 5px !important;
        flex: 1;
        max-width: 100%;
    }
    
    .vs-header {
        h5 {
            font-weight: 600;
        }
    }
    
    h5 {
        margin: 0;
    }
`

const OutputTableColumn = ({row, col, metaInformation, index, headers, setGlobalState, globalState}) => {
    return (<Col className="vs-table-col" span={Math.floor(24 / (row ? row.length : 1))}>
        {typeof col === 'object' && col && col['type'] === 'DateTime' && <DateTime value={col['props']['value']}/>}
        {typeof col !== 'object' &&
        <SearchableOutputValue content={col} setGlobalState={setGlobalState} globalState={globalState}
                               imposedType={metaInformation ? metaInformation[`${Object.values(headers)[index]}`] : undefined}/>}
    </Col>);

}

const OutputTable = ({data, setGlobalState, globalState}: Context) => {

    return (
        <TableWrapper>
            <Row className="vs-header">
                {data.props.headers && data.props.headers.map(header => (
                    <Col className="vs-table-col"
                         span={Math.floor(24 / (data.props.headers ? data.props.headers.length : 1))}>
                        <h5>{header}</h5>
                    </Col>))
                }
            </Row>
            {data.props.rows && data.props.rows.map((row) => (
                <Row>
                    {!Array.isArray(row) && typeof row === 'object' &&
                    Object.values(row).map((r, i) => <OutputTableColumn col={r} row={row}
                                                                   headers={data.props.headers}
                                                                   index={i}
                                                                   metaInformation={data.props.metaInformation}
                                                                   setGlobalState={setGlobalState}
                                                                   globalState={globalState}/>)}
                    {Array.isArray(row) && row.map((r, i) => <OutputTableColumn col={r} row={row}
                                                                           index={i}
                                                                           headers={data.props.headers}
                                                                           metaInformation={data.props.metaInformation}
                                                                           setGlobalState={setGlobalState}
                                                                           globalState={globalState}/>)}
                </Row>)
            )
            }
        </TableWrapper>
    );
}


export default OutputTable;
