import React, {useEffect, useState} from 'react'
import WidgetItemInfo from "../../models/WidgetItemInfo";
import styled from "styled-components";
import {Button, Checkbox, Col, Row, Spin, Tooltip} from "antd";
import {InfoCircleFilled, LoadingOutlined, SettingOutlined} from "@ant-design/icons/lib";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import WidgetInfoModal from "./WidgetInfoModal";
import {groupOutputs} from "../../utils/WidgetOutputUtil";
import {ProgressInfo} from "../../models/ProgressInfo";
import {sendWidgetNewOutputs} from "../../App";
import WidgetMissingAuthModal from "./WidgetMissingAuthModal";


export const WidgetListItemWrapper = styled.div`
  background-color: ${p => p['validSetup'] ? '#c9dbd7' : '#c9c9c9'};
  width: 100%;
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
  position: relative;
  font-size: 18px;
  align-items: center;

  .margins {
    margin-left: 5px;
    margin-right: 5px;
  }

  .status {
    height: 16px;
    width: 16px;
    display: flex;
    margin-right: 5px;
  }

  .title {
    font-weight: 500;
    cursor: default;
    width: 80%;

    .widget-status {
      font-weight: 400;
    }

    .widget-status-configured {
      color: #5c5c5c;
    }

    .widget-status-not-configured {
      color: #707070;
    }
  }

  .execute {
    position: absolute;
    right: 0;
  }

  .sk-widget-info-btn {
    position: absolute;
    right: 55px;
  }

  .sk-autorun-chk {
    width: 25px;
  }

`;

interface Context extends GlobalComponentContext {
    widget: WidgetItemInfo
    setShowSettings: Function
    executeWidget: Function
    setWidgetExecutionProgress: (info: ProgressInfo) => void;
    onSubmit: Function
}

const WidgetListItem = ({widget, setShowSettings, globalState, executeWidget, setGlobalState, setWidgetExecutionProgress, onSubmit}: Context) => {
    const [showInfo, setShowInfo] = useState(false);
    const [showMisingKeysDialog, setShowMisingKeysDialog] = useState(false);
    const [authorization, setAuthorization] = useState({});
    const [executing, setExecuting] = useState(false);
    useEffect(() => {
        const savedSettings = localStorage.getItem('WIDGET_SETTINGS');
        let config;
        if (savedSettings) {
            config = JSON.parse(savedSettings);
        } else {
            config = globalState.widgetConfiguration;
        }
        if (!config || Object.keys(config).length <= 0) {
            return;
        }

        const auth = {};
        config.find(c => c.name === widget.name)
            ?.fields.forEach(f => {
            auth[f.key] = f.value;
        });
        setAuthorization(auth);
    }, [globalState.filters, globalState.widgetConfiguration, widget.name])

    const [validSetup, setValidSetup] = useState(false);

    useEffect(() => {
        if (authorization) {
            setValidSetup(Object.values(authorization) && Object.values(authorization).filter(v => v).length === Object.values(authorization).length)
        }
    }, [authorization]);


    return (
        <WidgetListItemWrapper validSetup={validSetup}>
            <div className="status">
                <img src={widget.icon} alt={widget.display}/>
            </div>
            <div>
                <Button type="text" icon={<SettingOutlined/>} onClick={() => {
                    window.location.hash = encodeURIComponent(widget.id);
                    setShowSettings(true);
                }}/>
            </div>
            <div className="sk-autorun-chk">
                {validSetup && <Tooltip title="Show in extension" mouseEnterDelay={0.5}>
                    <Checkbox checked={widget.autorun} onChange={e => {
                        widget.autorun = e.target.checked;
                        onSubmit(widget.id, [], widget.autorun);
                    }}/>
                </Tooltip>}
            </div>

            <div className="title">
                <Row>
                    <Col span={8}>
                        {widget.display}
                    </Col>
                    <Col span={16} className={`widget-status ${validSetup ? 'widget-status-configured' : 'widget-status-not-configured'}`}>
                        {!validSetup && 'NOT CONFIGURED'}
                        {validSetup && 'ACTIVE'}
                        {validSetup && widget.autorun && ', EXTENSION'}
                    </Col>
                </Row>
            </div>
            <div className="sk-widget-info-btn">
                <Button
                    type="text"
                    icon={<InfoCircleFilled/>}
                    style={{background: 'inherit', opacity: '75%'}}
                    onClick={() => {
                        setShowInfo(true);
                    }}
                />
            </div>
            <div className="execute margins">
                {validSetup && <Button size="small" onClick={async () => {
                    if (executing) {
                        return;
                    }
                    if (widget.payWall !== 'Free') {
                        const missingKeys = Object.keys(authorization).filter(key => !authorization[key] || authorization[key] === '' || authorization[key] === 'changeit')
                        if (missingKeys.length > 0) {
                            setShowMisingKeysDialog(true)
                            return;
                        }
                    }
                    let outputs = [];
                    let oc = [];
                    let inputs = globalState.searchInput.split('\n');
                    setExecuting(true);
                    for (const input of inputs) {
                        const resp = await executeWidget(widget, authorization, input);
                        outputs.push(resp.data);
                        if (inputs.length > 1) {
                            setWidgetExecutionProgress({
                                currentStep: outputs.length,
                                totalSteps: inputs.length,
                                percent: Math.ceil(outputs.length * 100 / (inputs.length))
                            });
                        }
                        oc = groupOutputs(oc, outputs);
                        const widgetOutputs = [...oc, ...globalState.widgetOutputs.map(wo => ({...wo, old: true}))];
                        setGlobalState({
                            ...globalState,
                            widgetOutputs
                        });
                        localStorage.setItem("WIDGET_OUTPUTS", JSON.stringify(widgetOutputs));
                        sendWidgetNewOutputs(widgetOutputs.filter(w => !w.old))
                    }
                    setExecuting(false)
                }}>
                    {executing && <Spin indicator={<LoadingOutlined/>}/>}
                    {!executing ? 'Run' : ''}
                </Button>}
            </div>
            {showInfo &&
                <WidgetInfoModal widget={widget} setShowInfo={setShowInfo} showInfo={showInfo} validSetup={validSetup}
                                 setShowSettings={setShowSettings}/>}
            <WidgetMissingAuthModal widget={widget} setShowInfo={setShowMisingKeysDialog} showInfo={showMisingKeysDialog} validSetup={validSetup}
                                    setShowSettings={setShowSettings}/>
        </WidgetListItemWrapper>
    );
}

export default WidgetListItem
