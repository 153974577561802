import React, {useState} from 'react';
import styled from 'styled-components';
import {formatLink} from "../../utils/LinkUtils";
import LinkItemInfo from "../../models/LinkItemInfo";
import {Button, Checkbox, Col, Popover, Row, Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {WidgetListItemWrapper} from "../Widgets/WidgetListItem";

const gutter = 8;
const faviconHeight = 24;

const LinkWrapper = styled.div`
  background-color: #afafaf;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
 

  &:focus,
  &:hover,
  &:active {
    background-color: #e5e5e5;
  }

  a {
    font-size: 12px;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }

  a:hover {
    text-decoration: none;
  }

  img {
    width: ${faviconHeight}px;
    height: ${faviconHeight}px;
    margin-right: 5px;
  }

  img:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 5px;
    width: ${faviconHeight}px;
    height: ${faviconHeight}px;
    background-color: #eee;
  }
`;

const fallbackSrc =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

interface Context {
    link: LinkItemInfo,
    value: string,
    searchCount: number
    onChangeAutorun: Function
}

const LinkItem = ({link, value, searchCount, onChangeAutorun}: Context) => {
    const {id, display, url} = link;
    const [showPopover, setShowPopover] = useState(false);
    return (
        <div
            key={id}
            style={{
                marginBottom: gutter,
                width: '25%',
                paddingRight: gutter / 2,
                paddingLeft: gutter / 2
            }}
        >
            {url && <LinkWrapper>
                {searchCount > 9 &&
                <Popover overlayStyle={{width: 215, textAlign: 'center'}}
                         content={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                             <Button onClick={() => {
                                 setShowPopover(false);
                                 value.split('\n').filter(v => v)
                                     .forEach(v => window.open(formatLink(url, v), '_blank', 'noopener noreferrer'))

                             }} type={"primary"}>Yes</Button>
                             <Button onClick={() => {
                                 setShowPopover(false);
                             }
                             }>No</Button>
                         </div>}
                         style={{textAlign: "center"}}
                         title={`This will open ${searchCount} new tabs, do you want to continue ?`}
                         trigger="click"
                         visible={showPopover}
                         disabled={link.types.includes('image')}
                         onVisibleChange={(v) => setShowPopover(v)}
                >
                    <span>
                        <Row>
                            <Col span={4}>
                                <img
                                    alt="button-icon"
                                    onError={err => {
                                        const {target} = err;
                                        // @ts-ignore
                                        target.src = fallbackSrc;
                                    }}
                                    src={link.icon || fallbackSrc}
                                />
                            </Col>
                            <Col span={15}>
                                <span style={{lineHeight: `${faviconHeight}px`}}>{display}</span>
                            </Col>
                            <Col span={3}>
                                <Tooltip title="Show in extension" mouseEnterDelay={0.5}>
                                    <Checkbox checked={link.autorun}
                                              onClick={(e) => e.stopPropagation()}
                                              onChange={e => {
                                                  link.autorun = e.target.checked
                                                  onChangeAutorun(link, link.autorun);
                                              }}/>
                                </Tooltip>
                        </Col>
                            <Col span={2}>
                                <Tooltip title={link.description} mouseEnterDelay={0.5}>
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </Col>
                        </Row>
                    </span>
                </Popover>}
                {searchCount <= 9 && <span onClick={() => {
                    if (link.types.includes('image')) {
                        return;
                    }
                    setShowPopover(false);
                    value.split('\n').filter(v => v)
                        .forEach(v => window.open(formatLink(url, v), '_blank', 'noopener noreferrer'))

                }}>
                    <Row style={{cursor: link.types.includes('image') ? 'default' : ''}}>
                        <Col span={4}>
                            <img
                                alt="button-icon"
                                onError={err => {
                                    const {target} = err;
                                    // @ts-ignore
                                    target.src = fallbackSrc;
                                }}
                                src={link.icon || fallbackSrc}
                            />
                        </Col>
                        <Col span={15}>
                            <span style={{lineHeight: `${faviconHeight}px`}}>{display}</span>
                        </Col>
                        <Col span={3}>
                            <Tooltip title="Show in extension" mouseEnterDelay={0.5}>
                                <Checkbox checked={link.autorun}
                                          onClick={(e) => e.stopPropagation()}
                                          onChange={e => {
                                              link.autorun = e.target.checked
                                              onChangeAutorun(link.id, link.autorun);
                                          }}/>
                            </Tooltip>
                        </Col>
                        <Col span={2}>
                            <Tooltip title={link.description} mouseEnterDelay={0.5}>
                                <InfoCircleOutlined/>
                            </Tooltip>
                        </Col>
                    </Row>
                </span>}
            </LinkWrapper>}
        </div>
    );
};

export default LinkItem;
