import React, {useEffect, useState} from 'react';
import './App.css';
import 'antd/dist/antd.css';
import MainView, {DisplayModes} from './components/MainView';
import {Route, Routes} from 'react-router-dom';

// @ts-ignore
Array.prototype.forEachParallel = async function (
    func: (item: any) => Promise<void>
): Promise<void> {
    await Promise.all(this.map(async (item: any) => await func(item)));
};

export const BASE_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL;

export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

export const triggerOnExternalCloseEvent = () => {
    if (!isChrome || !chrome.runtime) {
        return;
    }
    chrome.runtime.sendMessage(process.env.REACT_APP_VORTIMO_EXTENSION_ID, {message: 'CLOSE_DETAIL'}, () => {
    });
}

export const sendWidgetNewOutputs = (outputs) => {
    broadCastMessage('NEW_WIDGET_OUTPUTS', outputs)
}

export const sendNewWidgetsConfig = (config) => {
    broadCastMessage('WIDGET_AUTORUN_SETTINGS', config)
}

export const sendNewLinksConfiguration = (linksConfiguration) => {
    broadCastMessage('LINKS_CONFIG', linksConfiguration)
}

export const sendWidgetSettingsConfiguration = (widgetSettings) => {
    broadCastMessage('WIDGET_SETTINGS', widgetSettings)
}

export const sendNewTypesInfo = (typesInfo) => {
    broadCastMessage('TYPES_INFO', typesInfo)
}

const broadCastMessage = (message, data, cb = (response) => {
}) => {
    if (!isChrome || !chrome.runtime) {
        return;
    }

    chrome.runtime.sendMessage(process.env.REACT_APP_VORTIMO_EXTENSION_ID, {message, data}, () => {
        if (chrome.runtime.lastError) {
            // check for errors in case the extension is not installed
        }
    });
    chrome.runtime.sendMessage(process.env.REACT_APP_SKYLIGHT_EXTENSION_ID, {message, data}, (response) => {
        if (chrome.runtime.lastError) {
            // check for errors in case the extension is not installed
            return;
        }
        cb(response);
    });
}

export const isSkylightExtensionInstalled = () => {
    return new Promise((resolve) => {
            if (isChrome && chrome.runtime) {
                chrome.runtime.sendMessage(
                    process.env.REACT_APP_SKYLIGHT_EXTENSION_ID,
                    {message: 'IS_VORTIMO_SKYLIGHT_EXT_INSTALLED'},
                    (response) => {
                        resolve(response && response.installed)
                        if (chrome.runtime.lastError) {
                            resolve(false)
                        }
                    });
            } else {
                resolve(false)
            }
        }
    );
}

function App() {

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const displayMode = queryParams?.get('displayMode');
        const isFromExtInstall = queryParams?.get('isFromExtInstall');
        if (isFromExtInstall) {
            // @ts-ignore
            plausible('extension_install')
        }
        if (displayMode !== DisplayModes.SIMPLIFIED) {
            sendWidgetSettingsConfiguration(JSON.parse(localStorage.getItem("WIDGET_SETTINGS") || process.env.REACT_APP_DEFAULT_WIDGET_CONFIG || '{}'))
            sendNewLinksConfiguration(JSON.parse(localStorage.getItem("LINKS_CONFIG") || process.env.REACT_APP_DEFAULT_LINKS_CONFIG || '{}'));
            sendNewWidgetsConfig(JSON.parse(localStorage.getItem("WIDGET_AUTORUN_SETTINGS") || "{}"))
        }
    }, [])

    const [forceRefresh, setForceRefresh] = useState(0);
    const [showSettings, setShowSettings] = useState(false);
    const [controlledFocus, setControlledFocus] = useState(false);


    useEffect(() => {
        broadCastMessage('GET_OUTPUTS', {}, (response) => {
            if (response) {
                const currentOutputs = JSON.parse(localStorage.getItem("WIDGET_OUTPUTS")) || [];
                const currentOutputIds = currentOutputs.map(o => o.id)
                const newOutputs = JSON.parse(response).filter(w => !currentOutputIds.includes(w.id))
                let updatedOutputs = [...newOutputs, ...currentOutputs];
                localStorage.setItem("WIDGET_OUTPUTS", JSON.stringify(updatedOutputs))
                if (updatedOutputs.length !== currentOutputs.length) {
                    setForceRefresh(Math.floor(Math.random() * 100))
                }
            }
        })
    }, [forceRefresh]);

    useEffect(() => {
        const isIframed = () => {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }
        if (isIframed()) {
            broadCastMessage('GET_IFRAME_CONFIGURATION', {}, (response) => {
                if (response) {
                    response.forEach(item => {
                        if (item.key && item.value) {
                            localStorage.setItem(item.key, item.value)
                        }
                    });
                }
            })
        }
    }, []);

    useEffect(() => {
        return () => {
            localStorage.removeItem('type');
            localStorage.removeItem('searchInput');
        }
    }, [])

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<MainView key={forceRefresh}
                                                   setForceRefresh={setForceRefresh}
                                                   controlledFocus={controlledFocus}
                                                   setControlledFocus={setControlledFocus}
                                                   showSettings={showSettings} setShowSettings={setShowSettings}/>}/>
            </Routes>
        </div>
    );
}

export default App;
