import React, {useEffect, useRef, useState} from 'react';
import GlobalComponentContext from "../../models/GlobalComponentContext";
import WidgetExecutionOutput from "../../models/WidgetExecutionOutput";
import WidgetOutputItem from "./WidgetOutputItem";
import {Button, message, Popover, Upload} from "antd";
import {BASE_URL} from "../../App";
import {saveAs} from "@progress/kendo-file-saver";
import {DownloadOutlined, LeftOutlined, RightOutlined, UploadOutlined} from "@ant-design/icons/lib";

interface Context extends GlobalComponentContext {
    visibleOutputs: any[],
    isSimplifiedDisplayMode: boolean,
    hasAutorunWidgets: boolean,
    setMaximizeOutputs: Function,
    maximizeOutputs: boolean,
    setControlledFocus: Function
}

const WidgetOutput = ({globalState, setGlobalState, visibleOutputs, isSimplifiedDisplayMode, hasAutorunWidgets, maximizeOutputs, setMaximizeOutputs, setControlledFocus}: Context) => {

    const [flattenedRuns, setFlattenedRuns] = useState(new Array<WidgetExecutionOutput>());
    const outputContainerRef = useRef(undefined);

    useEffect(() => {
        if (visibleOutputs) {
            const outputs = new Array<WidgetExecutionOutput>();
            visibleOutputs.forEach(
                wo => {
                    if (wo && wo.widgets) {
                        wo.widgets.forEach(w => outputs.push({...w, old: wo.old}));
                    }
                }
            )
            setFlattenedRuns(outputs.filter(o => isSimplifiedDisplayMode ? !o.old : true));
        }
    }, [visibleOutputs, isSimplifiedDisplayMode])

    useEffect(() => {
        setGlobalState({...globalState, outputContainerRef})
        setTimeout(() => {
            if (outputContainerRef && outputContainerRef.current) {
                const scrollPosition = localStorage.getItem('lastOutputScrollPos');
                if (scrollPosition) {
                    outputContainerRef.current.scrollTop = +scrollPosition;
                } else {
                    outputContainerRef.current.scrollTop = 0;
                }
            }
        }, 100)
    }, [flattenedRuns, outputContainerRef])

    const onScroll = () => {
        localStorage.setItem('lastOutputScrollPos', outputContainerRef.current.scrollTop);
    }

    useEffect(() => {
        if (outputContainerRef) {
            outputContainerRef.current.addEventListener('scroll', onScroll, false);
        }
    }, [outputContainerRef])


    const clearWidgetOutput = () => {
        setGlobalState({...globalState, widgetOutputs: []});
        localStorage.setItem("WIDGET_OUTPUTS", '[]');
    }

    const [visiblePopover, setVisiblePopover] = useState(false);
    const [uploadVisiblePopover, setUploadVisiblePopover] = useState(false);


    const onBeforeUpload = async (file) => {
        if (file) {
            setControlledFocus(true);
            file.text().then(text => {
                clearWidgetOutput();
                setGlobalState({...globalState, widgetOutputs: JSON.parse(text)});
                localStorage.setItem("WIDGET_OUTPUTS", text);
                message.success(`${file.name} file uploaded successfully`);
                setTimeout(() => {
                    setControlledFocus(false);
                }, 3000)
            }).catch(() => {
                message.error("Unable to fetch text of file.")
                setTimeout(() => {
                    setControlledFocus(false);
                }, 3000)
            })
        }
        setUploadVisiblePopover(false);
        return Upload.LIST_IGNORE;
    }

    return (
        <div className={`sk-right-col ${isSimplifiedDisplayMode ? 'simplified' : ''}`}>
            {!isSimplifiedDisplayMode && <h2 style={{display: 'flex'}}>
                <Button
                    type="text"
                    onClick={() => setMaximizeOutputs(!maximizeOutputs)}
                    icon={maximizeOutputs ? <RightOutlined/> : <LeftOutlined/>}
                />
                Output

                <span style={{position: "absolute", right: 25}}>
                     <Popover
                         content={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                             <Upload name="configUploader" action='#' showUploadList={false}
                                     beforeUpload={onBeforeUpload}>
                                 <Button type={"primary"} onClick={() => setControlledFocus(true)}>Continue</Button>
                             </Upload>
                             <Button onClick={() => {
                                 setUploadVisiblePopover(false);
                             }}>Cancel</Button>
                         </div>}
                         style={{textAlign: "center"}}
                         title="Uploading will delete the current outputs!"
                         trigger="click"
                         visible={globalState.widgetOutputs && globalState.widgetOutputs.length > 0 && uploadVisiblePopover}
                         onVisibleChange={(v) => setUploadVisiblePopover(v)}
                     >
                        {globalState.widgetOutputs && globalState.widgetOutputs.length > 0 &&
                        <Button icon={<UploadOutlined/>}>Upload</Button>}
                         {(!globalState.widgetOutputs || globalState.widgetOutputs.length === 0) &&
                         <Upload name="configUploader" action='#' showUploadList={false}
                                 beforeUpload={onBeforeUpload}>
                             <Button icon={<UploadOutlined/>} onClick={() => setControlledFocus(true)}>Upload</Button>
                         </Upload>
                         }

                     </Popover>
                    {globalState.widgetOutputs && globalState.widgetOutputs.length > 0 &&
                    <Button icon={<DownloadOutlined/>} onClick={() => {
                        saveAs(new Blob([JSON.stringify(globalState.widgetOutputs, null, 2)]),
                          `OSINT-Tool-results-${new Date().toISOString().slice(0, 10)}.json`)
                    }}> Download </Button>}
                    {globalState.widgetOutputs && globalState.widgetOutputs.length > 0 && <Popover
                        content={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button onClick={() => {
                                setVisiblePopover(false);
                                clearWidgetOutput()
                            }} type={"primary"}>Yes</Button>
                            <Button onClick={() => {
                                setVisiblePopover(false);
                            }
                            }>No</Button>
                        </div>}
                        style={{textAlign: "center"}}
                        title="Are you sure ?"
                        trigger="click"
                        visible={visiblePopover}
                        onVisibleChange={(v) => setVisiblePopover(v)}
                    >
                        <Button> Clear </Button>
                    </Popover>}
                </span>
            </h2>}
            <div className={`sk-w-out-container ${isSimplifiedDisplayMode ? 'simplified' : ''}`} ref={outputContainerRef}>
                {!hasAutorunWidgets && (!flattenedRuns || flattenedRuns.length === 0) && isSimplifiedDisplayMode &&
                <h3 style={{textAlign: 'center', color: '#575b5e'}}>There are no autorun widgets configured, please
                    click <a
                        onClick={() => {
                            window.open(`${BASE_URL}?input=${globalState.searchInput}&type=${globalState.filters.type}&prefill=true`, '_blank');
                        }}>
                        here </a> to configure</h3>
                }
                {flattenedRuns && flattenedRuns.length > 0 && flattenedRuns.map((run, index) => (
                    <div style={{margin: 20}}><WidgetOutputItem run={run} index={index} globalState={globalState}
                                                                setGlobalState={setGlobalState}/></div>))}
            </div>
        </div>
    )
}

export default WidgetOutput;
