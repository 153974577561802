import {isNotEmpty} from "./ObjectUtils";

const NON_SEARCHABLE_KEYS = [
    'widgets.0.type',
    'widgets.0.props.provider',
    'widgets.0.props.icon',
    'widgets.0.props.description',
    'widgets.0.props.signupURL',
    'widgets.0.props.region',
    'widgets.0.props.payWall',
    'widgets.0.props.requestRate',
    'widgets.0.props.testValue',
    'widgets.0.props.priority',
    'widgets.0.props.authorization.fields.0.name',
    'widgets.0.props.authorization.fields.0.key',
    'widgets.0.props.authorization.fields.0.type',
    'widgets.0.props.authorization.fields.0.icon',
    'widgets.0.props.authorization.fields.0.value',
    'widgets.0.props.authorization.fields.1.name',
    'widgets.0.props.authorization.fields.1.key',
    'widgets.0.props.authorization.fields.1.type',
    'widgets.0.props.authorization.fields.1.icon',
    'widgets.0.props.authorization.fields.1.value'
]

export const shouldIndexKey = (key) => {
    return key && !NON_SEARCHABLE_KEYS.includes(key);
}

export const groupOutputs = (outputCombined: any[], outputs: any[]) => {
    if (!outputs || !outputCombined) {
        return [];
    }
    outputCombined = outputs.filter(o => isNotEmpty(o)).map(o => {
        const childSections = outputs.filter(o => isNotEmpty(o))
            .filter(out => out.widgets[0].props.name === o.widgets[0].props.name)
            .map(o => o.widgets[0].children);
        return {
            ...o, widgets: [{
                ...o.widgets[0],
                children: [...childSections.flat()]
            }]
        };
    });
    outputCombined = [...new Map(outputCombined.map(item => [item.widgets[0].props.name, item])).values()]
    return outputCombined;
}

