
export const flatten = (obj, prefix?, current?) => {
    prefix = prefix || []
    current = current || {}

    if (typeof (obj) === 'object' && obj !== null) {
        Object.keys(obj).forEach(key => {
            flatten(obj[key], prefix.concat(key), current)
        })
    } else {
        current[prefix.join('.')] = obj
    }

    return current
}

export const isEmpty = (o) => {
    return o === null || o === undefined || Object.keys(o).length === 0 ;
}

export const isNotEmpty = (o) => {
    return !isEmpty(o);
}
