import React, {useEffect, useState} from 'react';
import LinkInfo from "../../models/LinkInfo";
import styled from "styled-components";
import {Button, Col, Row, Tooltip} from "antd";
import LinkItem from "./LinkItem";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import {formatLink, nameToDisplay} from '../../utils/LinkUtils';
import {groupBy, orderBy} from 'lodash';
import {EditOutlined, GlobalOutlined} from '@ant-design/icons';
import {filterResultsByContext} from "../../utils/FilteringUtil";
import {isNotEmpty} from "../../utils/ObjectUtils";
import {sendNewLinksConfiguration} from "../../App";

interface Context extends GlobalComponentContext {
    links: LinkInfo | undefined
}

const SectionTitleWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0.2rem 0.2rem;
  margin-bottom: 0.4rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: none;
  border-right: none;

  h2 {
    margin: 0;
    font-size: 1rem !important;
  }
`;

const Links = ({links, globalState}: Context) => {

    const [showNoResults, setShowNoResults] = useState(false);
    const [groupedLinks, setGroupedLinks] = useState(undefined);
    const [groupKeys, setGroupKeys] = useState(undefined);
    const [configuredLinks, setConfiguredLinks] = useState([]);

    const onChangeAutorun = (link, autorun) => {
        const updatedConfiguredList ={...configuredLinks};
        if (!updatedConfiguredList[globalState.filters.type || 'any']) {
            updatedConfiguredList[globalState.filters.type || 'any'] = [];
        }
        if (autorun) {
            const currentLink = links.items.find(l => l.id === link);
            updatedConfiguredList[globalState.filters.type || 'any']
                .push(
                    {
                        provider: currentLink.provider,
                        display: currentLink.display,
                        icon: currentLink.icon,
                        description: currentLink.description,
                        region: currentLink.region,
                        payWall: currentLink.payWall,
                        url: currentLink.url,
                        category: currentLink.category,
                        priority: currentLink.priority,
                        id: currentLink.id,
                        autorun
                    })
        } else {
            updatedConfiguredList[globalState.filters.type || 'any'] = updatedConfiguredList[globalState.filters.type || 'any'].filter(l => l.id !== link);
        }
        setConfiguredLinks({...updatedConfiguredList});

        localStorage.setItem("LINKS_CONFIG", JSON.stringify(updatedConfiguredList));
        sendNewLinksConfiguration(updatedConfiguredList);
    }

    useEffect(() => {
        let linksConfiguration = JSON.parse(localStorage.getItem("LINKS_CONFIG") || process.env.REACT_APP_DEFAULT_LINKS_CONFIG || '{}');

        if (Array.isArray(linksConfiguration)) {
            linksConfiguration = JSON.parse(process.env.REACT_APP_DEFAULT_LINKS_CONFIG);
        }
        localStorage.setItem("LINKS_CONFIG", JSON.stringify(linksConfiguration));
        setConfiguredLinks(linksConfiguration);
    }, []);

    useEffect(() => {
        if (!links) {
            return;
        }
        const linksConfiguration = JSON.parse(localStorage.getItem("LINKS_CONFIG") || process.env.REACT_APP_DEFAULT_LINKS_CONFIG || '{}');

        let updatedLinks = [...links.items
                .filter((i) => filterResultsByContext(globalState, i))
                .map(l => {
                const configuredLink = linksConfiguration[globalState.filters.type || 'any']?.find(link => link.id === l.id);
                    return {...l, autorun: configuredLink?.autorun || false}
            })] || [];

        const byCategoryLinks = groupBy(updatedLinks, 'category');
        const grouped = Object.keys(byCategoryLinks);
        grouped.sort();
        setGroupedLinks(byCategoryLinks);
        setGroupKeys(grouped);
    }, [links, configuredLinks, globalState])

    const openCheckedAllLinks = () => {
        const allLinks = [];
        groupKeys.forEach(key => {
            allLinks.push(...groupedLinks[key])
        })

        openCheckedLinks(allLinks)
    }

    const openCheckedLinks = (links) => {
        openAllLinks(links.filter(l => {
            return l.autorun;
        }));
    }

    const openAllLinks = (links) => {
        links.forEach(link => {
            const {url} = link;
            const parsedLink = formatLink(url, globalState.searchInput);
            window.open(parsedLink);
        });
    }

    const [searchCount, setSearchCount] = useState(1);
    useEffect(() => {
        if (globalState.multilineSearch) {
            setSearchCount(globalState.searchInput.split('\n').filter(s => s).length);
        }
    }, [globalState.searchInput, globalState.multilineSearch]);

    useEffect(() => {
        if (groupKeys) {
            setShowNoResults(!groupKeys.find(groupKey => groupedLinks[groupKey].filter((i) => filterResultsByContext(globalState, i)).length > 0));
        }
    }, [groupKeys, groupedLinks, globalState])
    return (
        <div style={{border: '1px solid #b9b9b9', padding: 5, flex: 1, overflow: 'hidden', margin: '5px 0 0'}}>
            <h2>Links
                <Tooltip title="Suggest Link.">
                    <Button size={"large"} icon={<EditOutlined/>} type={"text"}
                            onClick={() => window.open('https://www.vortimo.com/skylight-suggest', '_blank')}/>
                </Tooltip>
                <span style={{position: "absolute", right: 25}}>
                    <Button style={{backgroundColor: '#a8d5dc'}}
                        onClick={() => openCheckedAllLinks()}> Open checked </Button>
                </span>
            </h2>
            <div style={{padding: 10, overflow: 'auto', height: 'calc(100% - 25px)'}}>
                {isNotEmpty(groupKeys) && groupKeys.map((groupKey, index) => {
                    const sortedLinks = orderBy(groupedLinks[groupKey], ['priority', 'provider'], ['desc', 'asc']);
                    return (
                        <React.Fragment key={index}>
                            {sortedLinks && sortedLinks.length > 0 &&
                            <div key={groupKey} style={{marginBottom: '0.5rem'}}>
                                <SectionTitleWrapper>
                                    <Row>
                                        <Col span={18}>
                                            <h2>{nameToDisplay(groupKey)}</h2>
                                        </Col>
                                        <Col span={3}>
                                            {!globalState.multilineSearch && globalState.filters.type !== 'image' && <Button
                                                style={{float: 'right'}}
                                                type="text"
                                                icon={<GlobalOutlined/>}
                                                onClick={() => {
                                                    openCheckedLinks(sortedLinks)
                                                }}
                                            >
                                                Open checked
                                            </Button>}
                                        </Col>
                                        <Col span={3}>
                                            {!globalState.multilineSearch && globalState.filters.type !== 'image' && <Button
                                                style={{float: 'right'}}
                                                type="text"
                                                icon={<GlobalOutlined/>}
                                                onClick={() => {
                                                    openAllLinks(sortedLinks)
                                                }}
                                            >
                                                Open all
                                            </Button>}
                                        </Col>
                                    </Row>
                                </SectionTitleWrapper>
                                <div style={{marginLeft: -4, marginRight: -4, display: 'flex', flexWrap: 'wrap'}}>
                                    {sortedLinks
                                        .map((link, index) => {
                                            return <LinkItem key={index} link={link}
                                                             searchCount={searchCount}
                                                             onChangeAutorun={onChangeAutorun}
                                                             value={globalState.searchInput}/>;
                                        })}
                                </div>
                            </div>}
                        </React.Fragment>)
                })}
                {showNoResults && <h2>No links for this type (yet)</h2>}
            </div>
        </div>
    )
}

export default Links;
