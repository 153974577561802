const is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
const is_safari = navigator.userAgent.indexOf('Safari') > -1;

function isSafariBrowser() {
  if (is_safari) {
    if (is_chrome)  // Chrome seems to have both Chrome and Safari userAgents
      return false;
    else
      return true;
  }
  return false;
}

const types = {
  'email-address': {
    regex: [
      new RegExp(
        '^[a-zA-Z0-9](?:[a-zA-Z0-9!#$%&\'*+=?^_`|~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+=?^_`|~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])$')]
  },
  'ip-address': {
    regex: [
      new RegExp(
        '^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$'),
      new RegExp('(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))')
    ]
  },
  'crypto-address#bitcoin-address': {
    regex: [
      new RegExp(`^${!isSafariBrowser() ? '(?<![\\/\\w\\d])' : ''}(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,35}(?![\\/\\w\\d])$`),
      new RegExp('^0x[a-fA-F0-9]{40}$'),
      new RegExp('^0x([A-Fa-f0-9]{64})$')
    ]
  },
  'phone-number': {
    regex: [
      new RegExp(`^${!isSafariBrowser() ? '(?<=[\\s,])' : ''}(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}(?![0-9])(?=[\\s.,])$`),
      new RegExp('^[+]*[(]?[0-9]{1,4}[)]?[-\\s./0-9]*$'),
      new RegExp('^([0-9]+)([\\s.-]|–)?([0-9]+)([\\s.-]|–)([0-9]+)$')
    ],
    rejectCondition: (value) => {
      if (!value) {
        return true;
      }
      return value.replace(/[^0-9]/g, '').length < 4 && value.replace(/[^a-z]/g, '').length < 7;
    }
  },
  'name': {
    regex: [
      new RegExp(
        '^([A-Z\\u00C0-\\u017F]{1,2}[a-z\\u00C0-\\u017F]{0,20}(([ -]?[A-Za-z\\u00C0-\\u017F]{2,5}){0,2}([ -]?[A-Z]{1}[a-z\\u00C0-\\u017F]{2,20}))){1,2}(?=$|[\\s"\'.,!?;:()\\[\\]`])$')],
    rejectCondition: (value) => {
      if (!value) {
        return true;
      }
      return value.includes('.') && !new RegExp('\\b[A-Z].*?\\b').test(value);
    }
  },
  'url': {
    regex: [
      new RegExp(
        '^(http|ftp|https):\\/\\/([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w@?^=%&:\\/~+#-]*[\\w@?^=%&\\/~+#-])?$')]
  },
  'cidr': {
    regex: [
      new RegExp(
        '^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})[-/](25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$')]
  },
  'hash': {
    regex: [
      new RegExp(`^${!isSafariBrowser() ? '(?<![\\w\\d/])' : ''}[a-fA-F0-9]{32}(?![\\w\\d])$`),
      new RegExp(`^${!isSafariBrowser() ? '(?<![\\w\\d/])' : ''}[A-Fa-f0-9]{64}(?![\\w\\d/])$`),
      new RegExp(`^${!isSafariBrowser() ? '(?<![\\w\\d/])' : ''}[A-Fa-f0-9]{40}(?![\\w\\d/])$`)
    ]
  },
  'hashtag': { regex: [new RegExp(`^${!isSafariBrowser() ? '(?<![\\/])' : ''}#([A-Za-z][A-Za-z0-9-_]{1,15})(?=$|\\s)$`)] },
  'gps-coordinates': {
    regex: [
      new RegExp('^[-+]?[\\d]{1,2}\\.\\d{2,6},(\\s{0,3})[-+]?[\\d]{1,2}\\.\\d{2,6}$')]
  },
  'alias': {
    regex: [new RegExp('^(?=^|[^\\/\\w])@([A-Za-z0-9-_]{1,15})(?=$|[^\\/\\w])$')],
    enforcerCondition: (value) => {
      if (!value) {
        return false;
      }
      return value.startsWith('@') && !value.includes('.');
    }
  },
  'website': {
    regex: [
      new RegExp(
        '^(www.)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z]$')],
    rejectCondition: (value) => {
      if (!value) {
        return true;
      }
      return value.includes(' ');
    }
  },
  'domain': {
    regex: [
      new RegExp('^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z]$')],
    rejectCondition: (value) => {
      if (!value) {
        return true;
      }
      return value.includes(' ');
    }
  }
};

const strongTypes = [
  'email-address',
  'ip-address',
  'hashtag',
  'cidr',
  'hash',
  'crypto-address#bitcoin-address',
  'url',
  'gps-coordinates'
];

const weakTypes = Object.keys(types).filter(t => !strongTypes.includes(t));

export default function deduceType(input) {
  const type = strongTypes.find(key => types[key].regex.find(r => r.test(input.trim())));
  if (type) {
    return type;
  }
  const possibleTypes = [...weakTypes];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < weakTypes.length; i++) {
    const currentType = types[weakTypes[i]];
    if (currentType.enforcerCondition && currentType.enforcerCondition(input)) {
      return weakTypes[i];
    }
    if (currentType.rejectCondition && currentType.rejectCondition(input)) {
      possibleTypes.splice(possibleTypes.indexOf(weakTypes[i]), 1);
    }

    if (possibleTypes.length === 1) {
      return possibleTypes[0];
    }
  }
  // @ts-ignore
  return Object.keys(types).find(key => types[key].regex.find(r => r.test(input.trim())));
}

