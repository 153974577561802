import {CopyOutlined, DeleteOutlined, DownloadOutlined, SearchOutlined, UploadOutlined} from '@ant-design/icons';
import Collapse from 'antd/es/collapse';
import {debounce} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Icon, {FileSyncOutlined} from "@ant-design/icons/lib";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import {Button, Col, Input, message, Popover, Row, Upload} from 'antd';
import TextArea from "antd/lib/input/TextArea";
import WidgetInfo from "../../models/WidgetInfo";
import lunr from 'lunr';
import WidgetBasicDetails, {WidgetWrapper} from './WidgetBasicDetails';
import {saveAs} from "@progress/kendo-file-saver";
import {DisplayModes} from "../MainView";
import onSubmitWidgetChanges, {onSubmitWidgetConfigurationChanges} from "../../utils/WidgetConfigUtil";
import {sendNewLinksConfiguration, sendNewWidgetsConfig, sendWidgetSettingsConfiguration} from "../../App";
import GlobalState from "../../models/GlobalState";

const WidgetInputWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    float: right;
  }

  > span {
    flex-grow: 1;
    text-overflow: ellipsis;
    max-width: 90%;
    overflow: hidden;
  }

  > input {
    flex-grow: 1;
  }
`

const WidgetInput = ({field, widgetId, onSubmit}) => {
    const [value, setValue] = useState(field.value);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);


    const onSave = () => {
        setEdit(false);
        onSubmit(widgetId, {...field, value});
    }

    return (<WidgetInputWrapper>
        {edit && <input style={{width: '82%', padding: '5px 10px', boxSizing: 'border-box'}} value={value}
                        onChange={(e) => setValue(e.target.value)}/>}
        {edit && <Button onClick={onSave}>Save</Button>}
        {!edit && <span>{value}</span>}
        {!edit && <Button onClick={() => setEdit(true)}>Edit</Button>}
    </WidgetInputWrapper>)
};

interface Context extends GlobalComponentContext {
    widgets: WidgetInfo,
    setWidgets: Function,
    typesInfo: Object
}

class GeneralSettings {
    widgetSettings: Object
    linkAutorun: Object
    widgetAutorunConfig: Object
}

const GeneralSettingsWrapper = styled.div`
  height: 100%;

  > button {
    position: absolute;
    top: 0px;
    right: 15px;
    z-index: 1;
  }

  .ant-input-disabled {
    color: #000000 !important;
    cursor: unset !important;
  }

`

const WidgetLocalStorageConfig = ({setting, getUpdatedConfig, setGeneralSettings, onJsonChange}) => {
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(JSON.stringify(setting, null, 2));

    useEffect(() => {
        setValue(JSON.stringify(setting, null, 2))
    }, [setting])

    const onSave = () => {
        try {
            const parsedValue = JSON.parse(value);
            const updatedConfig = getUpdatedConfig(parsedValue);
            setGeneralSettings(updatedConfig);
            onJsonChange(updatedConfig);
            setEdit(false)
        } catch (err) {
            // Invalid JSON will set value once the config is valid
        }
    }

    return (
        <GeneralSettingsWrapper>

            {edit && <Button onClick={onSave}>Save</Button>}
            {!edit && <Button onClick={() => setEdit(true)}>Edit</Button>}
            <TextArea
                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                disabled={!edit}
                style={{width: '100%', height: '100%', resize: 'none'}}
                value={value}
                onChange={e => {
                    setValue(e.target.value)
                }}
            />
        </GeneralSettingsWrapper>
    )
}

interface PanelContext {
    defaultActiveKey: string
    visibleWidgets: WidgetInfo
    onSubmit: Function,
    typesInfo: Object
}

const WidgetsPanel = ({defaultActiveKey, visibleWidgets, onSubmit, typesInfo}: PanelContext) => {

    return <Collapse defaultActiveKey={[defaultActiveKey.slice(1, defaultActiveKey.length)]}
                     style={{
                         border: 0,
                         height: '100%',
                         overflowY: 'auto',
                         position: "absolute",
                         width: '100%'
                     }}>
        {visibleWidgets.items.filter(w => w).map(widget => (
            <Collapse.Panel header={widget.name} key={widget.id} id={widget.id} style={{
                backgroundColor: 'rgba(0, 0, 0, 0.14)',
                marginBottom: '5px',
                border: '1px solid #d9d9d9'
            }}>
                <WidgetBasicDetails widget={widget} onSubmit={onSubmit} typesInfo={typesInfo}/>
                {widget.fields && widget.fields.map((field, index) => (
                    <WidgetWrapper key={index}>
                        <div>
                            {field.name}
                        </div>

                        <WidgetInput field={field} widgetId={widget.id} onSubmit={onSubmit}/>
                    </WidgetWrapper>
                ))}
            </Collapse.Panel>
        ))}
    </Collapse>;
}

function getGeneralSettingsFromState(globalState: GlobalState) {
    let value = JSON.parse(localStorage.getItem('WIDGET_SETTINGS') || '{}');
    if (Object.keys(value).length > 0) {
        value = value.map(widget => {
            widget.fields = widget.fields.map(f => ({name: f.name, key: f.key, value: f.value}))
            return widget;
        })
    }
    const linkAutorun = JSON.parse(localStorage.getItem('LINKS_CONFIG') || '{}')
    const settings = {
        widgetSettings: value,
        widgetAutorunConfig: globalState.widgetAutorunConfig,
        linkAutorun: linkAutorun
    };
    return settings;
}

const WidgetsListContainer = ({widgets, setGlobalState, globalState, typesInfo, setWidgets}: Context) => {
    const onSubmit = (widgetId, field) => {
        onSubmitWidgetConfigurationChanges(setGlobalState, globalState, widgets, widgetId, field);
    }

    const [generalSettings, setGeneralSettings] = useState(getGeneralSettingsFromState(globalState));

    useEffect(() => {
        const settings = getGeneralSettingsFromState(globalState);
        setGeneralSettings(settings)

        return () => {
            if (setGlobalState) {
                let widgetConfiguration = JSON.parse(localStorage.getItem('WIDGET_SETTINGS'));
                widgetConfiguration = widgetConfiguration.map(widget => {
                    widget.fields = widget.fields.map(f => ({name: f.name, key: f.key, value: f.value}))
                    return widget;
                })
                setGlobalState({...globalState, widgetConfiguration: widgetConfiguration});
            }
        }
    }, []);

    useEffect(() => {
        if (globalState.widgetConfiguration) {
            const linkAutorun = JSON.parse(localStorage.getItem('LINKS_CONFIG') || '{}')
            const widgetAutorun = JSON.parse(localStorage.getItem('WIDGET_AUTORUN_SETTINGS') || '{}')
            const settings: GeneralSettings = {
                widgetSettings: globalState.widgetConfiguration,
                widgetAutorunConfig: widgetAutorun,
                linkAutorun: linkAutorun
            };
            setGeneralSettings(settings);
        }
    }, [globalState.widgetConfiguration])

    const updateLocalStorage = (newConfig) => {

        const updatedVisibleWidgets = {...visibleWidgets};
        updatedVisibleWidgets.items.forEach(i => {
            const correspondingWidget = newConfig.widgetSettings.find(w => w.name === i.name)
            if (correspondingWidget) {
                console.log(correspondingWidget);
                i.fields.forEach(f => f.value = correspondingWidget.fields.find(cf => cf.key === f.key)?.value || f.value)
            }
        });

        setVisibleWidgets(updatedVisibleWidgets);
        localStorage.setItem('WIDGET_SETTINGS', JSON.stringify(newConfig.widgetSettings));
        localStorage.setItem('WIDGET_AUTORUN_SETTINGS', JSON.stringify(newConfig.widgetAutorunConfig));
        localStorage.setItem('LINKS_CONFIG', JSON.stringify(newConfig.linkAutorun));
        const updatedGlobalState = {
            ...globalState,
            widgetConfiguration: newConfig.widgetSettings,
            widgetAutorunConfig: newConfig.widgetAutorunConfig
        }
        setGlobalState(updatedGlobalState);
        sendNewWidgetsConfig(updatedGlobalState.widgetAutorunConfig);
        sendNewLinksConfiguration(newConfig.linkAutorun)
        sendWidgetSettingsConfiguration(newConfig.widgetSettings)
    };
    const onJsonChange = debounce(updateLocalStorage, 200);

    const onClearData = () => {
        const emptyConfig = {
            widgetSettings: globalState.widgetConfiguration,
            widgetAutorunConfig: {},
            linkAutorun: {}
        }
        updateLocalStorage(emptyConfig);
        setGlobalState({...globalState, widgetAutorunConfig: {}, widgetConfiguration: emptyConfig.widgetSettings})
        setGeneralSettings(emptyConfig);
        sendNewWidgetsConfig({})
        sendNewLinksConfiguration({})
    }

    const onFactoryReset = () => {
        console.log(JSON.parse(process.env.REACT_APP_DEFAULT_WIDGET_CONFIG), '?!@?!?@!?@');
        const defaultConfig = {
            widgetSettings: JSON.parse(process.env.REACT_APP_DEFAULT_WIDGET_CONFIG),
            widgetAutorunConfig: {},
            linkAutorun: JSON.parse(process.env.REACT_APP_DEFAULT_LINKS_CONFIG)
        }
        updateLocalStorage(defaultConfig);
        setGlobalState({
            ...globalState,
            widgetAutorunConfig: defaultConfig.widgetAutorunConfig,
            widgetConfiguration: defaultConfig.widgetSettings
        })
        setGeneralSettings(defaultConfig);
        sendNewWidgetsConfig(defaultConfig.widgetAutorunConfig);
        sendNewLinksConfiguration(defaultConfig.linkAutorun);
    }

    const [searchIndex, setSearchIndex] = useState();
    const [visibleWidgets, setVisibleWidgets] = useState(widgets);

    useEffect(() => {
        setVisibleWidgets(widgets);
        setSearchIndex(lunr(function () {
            // @ts-ignore
            this.field('paywall', {
                extractor: (doc = {}) => {
                    // @ts-ignore
                    return doc.paywall;
                }
            });
            // @ts-ignore
            this.field('types', {
                extractor: (doc = {}) => {
                    // @ts-ignore
                    return doc.types;
                }
            });
            // @ts-ignore
            this.field('name', {
                extractor: (doc = {}) => {
                    // @ts-ignore
                    return doc.name;
                }
            });
            // @ts-ignore
            this.field('description', {
                extractor: (doc = {}) => {
                    // @ts-ignore
                    return doc.description;
                }
            });
            // @ts-ignore
            this.field('region', {
                extractor: (doc = {}) => {
                    // @ts-ignore
                    return doc.region;
                }
            });
            // @ts-ignore
            this.field('value', {
                extractor: (doc = {}) => {
                    // @ts-ignore
                    return doc.value;
                }
            });
            // @ts-ignore
            this.ref('id');
            widgets.items.forEach(widget => {
                // @ts-ignore
                this.add(widget);
            });
        }));
    }, [widgets]);

    const debouncedSearch = debounce((searchValue) => {
        if (!searchIndex) {
            return;
        }
        // @ts-ignore
        const widgetIds = searchIndex.search(`*${searchValue}*`)
            .map(r => r.ref);
        setVisibleWidgets({url: widgets.url, items: widgets.items.filter(w => widgetIds.includes(w.id))});
    }, 100);

    const [defaultActiveKey, setDefaultActiveKey] = useState(decodeURIComponent(window.location.hash));

    useEffect(() => {
        if (defaultActiveKey) {
            let elementById = document.getElementById(defaultActiveKey.replace('#', ''));
            if (elementById) {
                elementById.scrollIntoView();
            }
        }
    }, [defaultActiveKey]);

    const isNormalDisplayMode = useMemo(() => {
        return DisplayModes.NORMAL === globalState.displayMode
    }, [globalState.displayMode])

    const [showClearDataPopover, setShowClearDataPopover] = useState(false);
    const [showFactoryResetPopover, setShowFactoryResetPopover] = useState(false);

    return (
        <>
            <Row style={{position: 'absolute', top: 0, right: '10%', left: '10%'}}>
                {isNormalDisplayMode &&
                    <>
                        <Col span={12} style={{paddingBottom: '10px'}}>
                            <Input className="ant-input-xl" placeholder="Search..."
                                   prefix={<SearchOutlined/>}
                                   onChange={e => debouncedSearch(e.target.value)}/>
                        </Col>
                        <Col span={12} style={{display: 'flex', justifyContent: 'end', alignItems: 'flex-start'}}>
                            <Popover
                                content={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Button onClick={() => {
                                        setShowClearDataPopover(false);
                                        onClearData()
                                    }} type={"primary"}>Yes</Button>
                                    <Button onClick={() => {
                                        setShowClearDataPopover(false);
                                    }
                                    }>No</Button>
                                </div>}
                                style={{textAlign: "center"}}
                                title="Are you sure ?"
                                trigger="click"
                                visible={showClearDataPopover}
                                onVisibleChange={(v) => setShowClearDataPopover(v)}
                            >
                                <Button icon={<DeleteOutlined/>} style={{height: '38px'}}>
                                    Clear checks
                                </Button>
                            </Popover>
                            <Popover
                                content={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Button onClick={() => {
                                        setShowFactoryResetPopover(false);
                                        onFactoryReset();
                                    }} type={"primary"}>Yes</Button>
                                    <Button onClick={() => {
                                        setShowFactoryResetPopover(false);
                                    }
                                    }>No</Button>
                                </div>}
                                style={{textAlign: "center"}}
                                title="Are you sure ?"
                                trigger="click"
                                visible={showFactoryResetPopover}
                                onVisibleChange={(v) => setShowFactoryResetPopover(v)}
                            >
                                <Button icon={<FileSyncOutlined/>} style={{height: '38px'}}>
                                    Factory reset
                                </Button>
                            </Popover>

                            <Upload name="configUploader" action='#' showUploadList={false} beforeUpload={async (file) => {
                                if (file) {
                                    file.text().then(text => {
                                        const parsedConfig = JSON.parse(text);
                                        onJsonChange(parsedConfig);
                                        setGeneralSettings({...parsedConfig});
                                        message.success(`${file.name} file uploaded successfully`);
                                    }).catch(() => message.error("Unable to fetch text of file."))

                                }
                                return Upload.LIST_IGNORE;
                            }}>
                                <Button icon={<UploadOutlined/>} style={{height: '38px'}}>Upload</Button>
                            </Upload>
                            <Button icon={<DownloadOutlined/>} style={{height: '38px'}} onClick={() => {
                                saveAs(
                                    new Blob([JSON.stringify(generalSettings, null, 2)]),
                                    `OSINT-TOOL-api-config-${new Date().toISOString().slice(0, 10)}.json`
                                )
                            }}> Download </Button>
                            <Button icon={<CopyOutlined/>} style={{height: '38px'}} onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(generalSettings, null, 2)).then(
                                    () => {
                                    }
                                );
                            }}> Copy </Button>
                        </Col>
                    </>
                }
            </Row>
            {globalState.showExecutionUrl && <Row>
                <Col span={24} style={{paddingBottom: '10px'}}>
                    <span>Execution URl:</span>
                    <Input className="ant-input-xl" placeholder="Execution URL" value={globalState.executionUrl}
                           onChange={(e) => {
                               localStorage.setItem("EXECUTION_URL", e.target.value);
                               setGlobalState({...globalState, executionUrl: e.target.value})
                           }}/>
                </Col>
            </Row>}
            <Row style={{height: '100%', position: 'relative'}} justify={"center"}>
                <Col span={isNormalDisplayMode ? 12 : 1} style={{paddingRight: '10px'}}>
                    {isNormalDisplayMode && <>
                        {visibleWidgets && <WidgetsPanel
                            defaultActiveKey={defaultActiveKey}
                            visibleWidgets={visibleWidgets}
                            onSubmit={onSubmit}
                            typesInfo={typesInfo}
                        />}
                    </>}
                </Col>
                <Col span={isNormalDisplayMode ? 12 : 23}>
                    <WidgetLocalStorageConfig
                        getUpdatedConfig={(parsedValue) => ({...generalSettings, ...parsedValue})}
                        onJsonChange={onJsonChange}
                        setGeneralSettings={setGeneralSettings}
                        setting={generalSettings}
                    />
                </Col>
            </Row>
        </>
    );
};

const WidgetSettingsListWrapper = styled.div`
  padding: 10px 10%;
  overflow: auto;
  height: 100%;
  position: relative;
  padding-top: 60px;
`;

interface ListContext extends GlobalComponentContext {
    widgets: WidgetInfo,
    setWidgets: Function,
    typesInfo: Object
}

const WidgetsSettingsList = ({widgets, globalState, setGlobalState, typesInfo, setWidgets}: ListContext) => {
    return (
        <WidgetSettingsListWrapper>
            {widgets &&
                <WidgetsListContainer widgets={widgets} globalState={globalState} setGlobalState={setGlobalState}
                                      setWidgets={setWidgets} typesInfo={typesInfo}/>}
        </WidgetSettingsListWrapper>
    );
};

export default WidgetsSettingsList;
