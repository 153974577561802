import {Button, Col, Modal, Row} from 'antd';
import React from 'react';
import styled from "styled-components";
import {
    ApiFilled,
    DollarOutlined,
    GlobalOutlined, InfoCircleFilled, PlayCircleOutlined,
    ReadOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UserOutlined
} from "@ant-design/icons/lib";
import {WidgetWrapper} from "../WidgetSettingsList/WidgetBasicDetails";
import {WidgetListItemWrapper} from "./WidgetListItem";

export const WidgetDetailsWrapper = styled.div`
  box-shadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0.8rem;

  strong {
    margin-right: 5px;
  }
  
  .anticon {
    margin-right: 5px;
  }

`;

const WidgetInfoModal = ({widget, showInfo, setShowInfo, validSetup, setShowSettings}) => {
    const modalHeader = (
        <WidgetListItemWrapper validSetup={validSetup} style={{backgroundColor: '#d2e1da'}}>
            <div className="status"/>
            <div>
                <Button type={"text"} size="small" icon={<SettingOutlined/>} onClick={() => {
                    window.location.hash = encodeURIComponent(widget.id);
                    setShowSettings(true);
                }}/>
            </div>
            <div className="title">
                {widget.display}
            </div>
        </WidgetListItemWrapper>
    );

    return (
        <Modal
            key={widget.id}
            id={widget.id}
            width="960px"
            title={modalHeader}
            visible={showInfo}
            maskClosable
            destroyOnClose
            footer={null}
            wrapClassName="widget-info-modal"
            onCancel={() => setShowInfo(false)}
        >
            <WidgetDetailsWrapper>
                <>
                    <Row
                        style={{
                            padding: '0 0.5rem',
                            overflow: 'hidden',
                            transition: 'max-height 0.5s ease-in-out',
                            height: 'auto',
                            width: 'auto',
                            overflowY: 'auto'
                        }}
                    >
                        <Col span={6}>
                            <Row>
                                <Col span={12}>
                                    <UserOutlined/>
                                    Integrator
                                </Col>
                                <Col span={12}>
                                    Vortimo
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <ApiFilled/>
                                    Provider
                                </Col>
                                <Col span={12}>
                                    {widget.provider}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <GlobalOutlined/>
                                    Region
                                </Col>
                                <Col span={12}>
                                    {widget.region}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <DollarOutlined/>
                                    Usage
                                </Col>
                                <Col span={12}>
                                    {widget.payWall}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={18}>
                            <p dangerouslySetInnerHTML={{__html: widget.description}}/>
                            <p>Sign-up at: <a href={widget.signupURL} target="_blank">{widget.signupURL}</a></p>
                        </Col>
                    </Row>
                </>
            </WidgetDetailsWrapper>
        </Modal>
    );
};

export default WidgetInfoModal;
