import WidgetExecutionOutput from "../../../models/WidgetExecutionOutput";
import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "antd";
import styled from "styled-components";
import deduceType from "../../../utils/InputTypeDeducer";
import SearchableOutputValue from "../SearchableOutputValue";
import GlobalComponentContext from "../../../models/GlobalComponentContext";

interface Context extends GlobalComponentContext {
    data: WidgetExecutionOutput
}

const Wrapper = styled.div`
.ant-col {
    font-size: 14px;
    padding: 5px 10px;
    text-align: left;
    box-shadow: inset 0 1px 0 0 rgb(16 22 26 / 15%);
    display: flex;
    align-items: center;
    overflow: hidden;
    
    h5 {
        font-size: 14px;
        margin: 0;
    }
}

.vs-first {
    background-color: #eeeeee;
    padding: 0 5px !important;
}
.vs-second {
    background-color: #fff;
    padding: 0 5px !important;
}

`

const TableColumn = ({row, col}) => {

    return (<Col span={Math.floor(24 / (row ? row.length : 1))}>
        <h5>{col}</h5>
    </Col>);
}

const PropertyOutputTable = ({data, setGlobalState, globalState}: Context) => {
    const obj = data.props.properties;
    return (
        <Wrapper>
            {
                Object.keys(obj).map(key => (
                    <Row>
                        <Col span={7} className={"vs-first"}>
                            <h5>{key}</h5>
                        </Col>
                        <Col span={17} className={"vs-second"}>
                            <SearchableOutputValue content={obj[key]} setGlobalState={setGlobalState} globalState={globalState}
                                                   imposedType={data.props.metaInformation ? data.props.metaInformation[key] : undefined}/>
                        </Col>
                    </Row>
                ))
            }
            <Row>
                {data.props.headers && data.props.headers.map(header => (
                    <Col span={Math.floor(24 / (data.props.headers ? data.props.headers.length : 1))}>
                        <h5>{header}</h5>
                    </Col>))
                }
            </Row>
            {data.props.rows && data.props.rows.map(row => (
                <Row>
                    {row.map(r => <TableColumn row={row} col={r}/>)}
                </Row>)
            )
            }
        </Wrapper>
    );
}


export default PropertyOutputTable;
