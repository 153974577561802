import WidgetItemInfo from "../models/WidgetItemInfo";
import LinkItemInfo from "../models/LinkItemInfo";
import GlobalState from "../models/GlobalState";

export const filterResultsByContext = (globalState: GlobalState, item: WidgetItemInfo | LinkItemInfo) => {
    let pass = true;
    if (globalState.filters) {
        if (globalState.filters.category && globalState.filters.category !== 'All' && item.category) {
            pass = pass && item.category === globalState.filters.category;
        }
        if (globalState.filters.type && globalState.filters.type !== 'any' && item.types) {
            pass = pass && (item.types.includes(globalState.filters.type) || item.types.filter(t => t.startsWith(globalState.filters.type)).length > 0);
        }
        if (globalState.filters.location && globalState.filters.location !== 'any' && item.region) {
            pass = pass && item.region === globalState.filters.location;
        }
        if (globalState.filters.payWall && globalState.filters.payWall !== 'any' && item.payWall) {
            pass = pass && item.payWall === globalState.filters.payWall;
        }
    }
    return pass;
}
