import {Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, {useEffect, useState} from "react";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import {sendWidgetSettingsConfiguration} from "../../App";

interface Context extends GlobalComponentContext {
    isModalVisible: boolean,
    setIsModalVisible: Function,
    setWidgetListKey: Function
}

const WidgetConfigurationModal = ({isModalVisible, setIsModalVisible, globalState, setGlobalState, setWidgetListKey}: Context) => {

    const [configuration, setConfiguration] = useState('');

    useEffect(() => {
        setConfiguration(JSON.stringify(globalState.widgetConfiguration, null, 2));
    }, []);


    useEffect(() => {
        setConfiguration(JSON.stringify(globalState.widgetConfiguration, null, 2));
    }, [globalState.widgetConfiguration])

    const onSubmit = () => {
        localStorage.setItem('WIDGET_SETTINGS', configuration);
        sendWidgetSettingsConfiguration(configuration);
        if (setGlobalState) {
            setGlobalState({...globalState, widgetConfiguration: JSON.parse(configuration)});
        }
        setIsModalVisible(false);
        setWidgetListKey(Math.floor(Math.random() * 100));
    };


    return (<Modal title="Widget configuration" visible={isModalVisible} onOk={onSubmit}
                   destroyOnClose okText="Save"
                   onCancel={() => setIsModalVisible(false)}>
        <TextArea
            style={{width: '100%', height: '100%'}}
            value={configuration}
            onChange={e => {
                setConfiguration(e.target.value);
            }}
        />
    </Modal>);
}

export default WidgetConfigurationModal;
