import React from 'react';
import PropTypes from 'prop-types';

const DateTime = ({ value }) => {
  const safeValue = value < 2000 * 1000000 ? value * 1000 : value;

  return <div>{new Date(safeValue).toDateString()}</div>;
};

DateTime.propTypes = {
  value: PropTypes.number.isRequired
};

export default DateTime;
