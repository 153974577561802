import React, {useEffect, useMemo, useRef, useState} from 'react';
import WidgetInfo from "../../models/WidgetInfo";
import WidgetListItem from "./WidgetListItem";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import {filterResultsByContext} from "../../utils/FilteringUtil";
import {Button, Tooltip} from "antd";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {groupOutputs} from "../../utils/WidgetOutputUtil";
import {ProgressInfo} from "../../models/ProgressInfo";
import {EditOutlined} from '@ant-design/icons';
import onSubmitWidgetChanges from "../../utils/WidgetConfigUtil";
import {DisplayModes} from "../MainView";
import deduceType from "../../utils/InputTypeDeducer";
import {isNotEmpty} from "../../utils/ObjectUtils";
import {sendWidgetNewOutputs} from "../../App";

interface Context extends GlobalComponentContext {
    widgets: WidgetInfo | undefined
    setShowSettings: Function
    setWidgetExecutionProgress: (info: ProgressInfo) => void;
}

let stopExecution = false;

const Widgets = ({widgets, globalState, setGlobalState, setShowSettings, setWidgetExecutionProgress}: Context) => {
    const [visibleWidgets, setVisibleWidgets] = useState([]);

    const isSimplifiedDisplayMode = useMemo(() => {
        return DisplayModes.SIMPLIFIED === globalState.displayMode
    }, [globalState.displayMode])

    const onSubmit = (widgetId, field, autorun) => {
        onSubmitWidgetChanges(setGlobalState, globalState, widgets, widgetId, field, autorun);
    }

    function isValidSetup(o) {
        // @ts-ignore
        return Object.keys(o).length > 0 && o.fields && Object.values(o.fields) && Object.values(o.fields).filter(f => isNotEmpty(f.value)).length === Object.values(o.fields).length;
    }

    useEffect(() => {
        if (!widgets || !widgets.items) {
            return;
        }
        const unorderedVisibleWidget = widgets.items
            .filter(i => i)
            .filter((i) => filterResultsByContext(globalState, i));

        const inOrder = [
            ...unorderedVisibleWidget.filter((o) => isValidSetup(o) && o.autorun),
            ...unorderedVisibleWidget.filter((o) => isValidSetup(o) && !o.autorun),
            ...unorderedVisibleWidget.filter((o) => !isValidSetup(o))
        ];
        setVisibleWidgets(inOrder);
    }, [widgets, globalState, globalState.filters.type, globalState.filters.imposedType])

    const executeWidget = async (widget, authorization, input) => {
        if (globalState.outputContainerRef) {
            // @ts-ignore
            globalState.outputContainerRef.current.scrollTop = 0;
        }
        Object.keys(authorization).map(k => authorization[k] = authorization[k].trim());
        let response = {
            data: {
                widgets: [
                    {
                        type: "Widget",
                        props: {
                            provider: widget.provider,
                            name: widget.name,
                            id: widget.id,
                        },
                        children: [
                            {
                                type: "WidgetError",
                                props: {
                                    title: "Could not execute request",
                                    description: `Could not execute request`,
                                },
                                children: [],
                            },
                        ],
                    }]
            }
        };

        try {
            response = await axios.get(`${globalState.executionUrl}${widgets.url}`, {
                params: {
                    id: widget.id,
                    value: input.trim(),
                    suggestedType: globalState.filters.type || deduceType(input),
                    authorization: btoa(JSON.stringify(authorization))
                }
            });

        } catch (e: any) {
            response.data = {
                widgets: [
                    {
                        type: "Widget",
                        props: {
                            provider: widget.provider,
                            name: widget.name,
                            id: widget.id,
                        },
                        children: [
                            {
                                type: "WidgetError",
                                props: {
                                    title: "Could not execute request",
                                    description: `${e.response ? e.response.data : e.message}`,
                                },
                                children: [],
                            },
                        ],
                    }]
            }
        }

        let widgetResponse: any = {...response.data};

        if (widgetResponse && widgetResponse.widgets && widgetResponse.widgets[0]?.children?.length === 0) {
            widgetResponse = {
                widgets: [
                    {
                        type: "Widget",
                        props: {
                            provider: widget.provider,
                            name: widget.name,
                            id: widget.id,
                        },
                        children: [
                            {
                                type: "WidgetError",
                                props: {
                                    title: "Empty Response",
                                    description: `Received empty response from widget.`,
                                },
                                children: [],
                            },
                        ],
                    }]
            }
        }
        return {data: {id: uuidv4(), ...widgetResponse}};

    }

    const [configuration, setConfiguration] = useState(undefined);
    useEffect(() => {
        const savedSettings = localStorage.getItem('WIDGET_SETTINGS');
        let config;
        if (savedSettings) {
            config = JSON.parse(savedSettings);
        } else {
            config = globalState.widgetConfiguration;
        }
        if (!config) {
            return;
        }
        setConfiguration(config);
    }, [globalState.widgetConfiguration]);

    useEffect(() => {
        stopExecution = globalState.stopWidgetExecution;
    }, [globalState.stopWidgetExecution])

    const executeAllWidgets = async (visibleWidgets) => {
        let outputs = []
        let oc = [];
        for (const widget of visibleWidgets) {
            if (stopExecution) {
                setGlobalState({...globalState, stopWidgetExecution: false});
                break;
            }
            const auth = {};
            configuration.find(c => c.name === widget.name)
                ?.fields.forEach(f => {
                auth[f.key] = f.value;
            });
            const lineNumbers = globalState.multilineSearch ? globalState.searchInput.split('\n').length : 1;
            if (Object.values(auth) && Object.values(auth).filter(v => v).length === Object.values(auth).length) {
                try {
                    if (globalState.multilineSearch) {
                        for (const input of globalState.searchInput.split('\n')) {
                            if (stopExecution) {
                                setGlobalState({...globalState, stopWidgetExecution: false});
                                setTimeout(() => {
                                    setWidgetExecutionProgress({
                                        totalSteps: 0,
                                        currentStep: 0,
                                        percent: 0
                                    });
                                }, 5000);
                                break;
                            }
                            setWidgetExecutionProgress({
                                currentStep: outputs.length,
                                totalSteps: visibleWidgets.length * lineNumbers,
                                percent: Math.ceil(outputs.length * 100 / (visibleWidgets.length * lineNumbers))
                            });
                            const resp = await executeWidget(widget, auth, input);
                            outputs.push(resp.data);
                            setWidgetExecutionProgress({
                                currentStep: outputs.length,
                                totalSteps: visibleWidgets.length * lineNumbers,
                                percent: Math.ceil(outputs.length * 100 / (visibleWidgets.length * lineNumbers))
                            });
                        }
                    } else {
                        setWidgetExecutionProgress({
                            currentStep: outputs.length,
                            totalSteps: visibleWidgets.length * lineNumbers,
                            percent: Math.ceil((outputs.length) * 100 / (visibleWidgets.length * lineNumbers))
                        });
                        const resp = await executeWidget(widget, auth, globalState.searchInput);
                        outputs.push(resp.data);
                    }
                } catch (e: any) {
                    outputs.push({
                        data: {
                            widgets: [
                                {
                                    type: "Widget",
                                    props: {
                                        provider: widget.provider,
                                        name: widget.name,
                                        id: widget.id,
                                    },
                                    children: [
                                        {
                                            type: "WidgetError",
                                            props: {
                                                title: "Could not execute request",
                                                description: `${e.response ? e.response?.data : e.message}`,
                                            },
                                            children: [],
                                        },
                                    ],
                                }]
                        }
                    });
                }
            } else {
                for (let i = 0; i < lineNumbers; i++) {
                    outputs.push({
                        data: {
                            widgets: [
                                {
                                    type: "Widget",
                                    props: {
                                        provider: widget.provider,
                                        name: widget.name,
                                        id: widget.id,
                                    },
                                    children: [
                                        {
                                            type: "WidgetError",
                                            props: {
                                                title: "Could not execute request",
                                                description: `Could not find api auth details`,
                                            },
                                            children: [],
                                        },
                                    ],
                                }]
                        }
                    });
                }
            }
            oc = groupOutputs(oc, outputs);
            setWidgetExecutionProgress({
                currentStep: outputs.length,
                totalSteps: visibleWidgets.length * lineNumbers,
                percent: Math.ceil(outputs.length * 100 / (visibleWidgets.length * lineNumbers))
            });
            const widgetOutputs = [...oc, ...globalState.widgetOutputs.map(wo => ({...wo, old: true}))];
            setGlobalState({
                ...globalState,
                widgetOutputs,
            });
            localStorage.setItem("WIDGET_OUTPUTS", JSON.stringify(widgetOutputs));
            sendWidgetNewOutputs(widgetOutputs.filter(w => !w.old))
        }
    }

    const executeAutorunWidgets = () => {
        executeAllWidgets(visibleWidgets.filter(w => w.autorun)).then(() => {
        });
    }

    const [firstTry, setFirstTry] = useState(true);
    const _firstTry = useRef<any>(firstTry);
    useEffect(() => {
        _firstTry.current = firstTry;
    }, [firstTry]);

    useEffect(() => {
        let urlSearchParams = new URLSearchParams(window.location.search);
        if (_firstTry.current && visibleWidgets.length > 0 && urlSearchParams.get("input") && !urlSearchParams.get("prefill") && !urlSearchParams.get("widgetId")) {
            executeAutorunWidgets();
            setFirstTry(false);
        } else if (_firstTry.current && visibleWidgets.length > 0 && urlSearchParams.get("input") && urlSearchParams.get("widgetId")) {
            executeAllWidgets(visibleWidgets.filter(w => w.id === urlSearchParams.get("widgetId"))).then(() => {
            });
            setFirstTry(false);
        }

    }, [visibleWidgets])


    return (
        <div style={{
            border: '1px solid #b9b9b9',
            margin: '5px 0px 15px 0',
            padding: 5,
            maxHeight: '50%',
            overflow: 'hidden',
            position: 'relative',
            display: `${isSimplifiedDisplayMode ? 'none' : ''}`
        }}>
            <div>
                <h2>Widgets
                    <Tooltip title="Suggest widget.">
                        <Button size={"large"} icon={<EditOutlined/>} type={"text"}
                                onClick={() => window.open('https://www.vortimo.com/skylight-suggest', '_blank')}/>
                    </Tooltip>
                    {visibleWidgets && visibleWidgets.length > 0 && <span style={{position: "absolute", right: 25}}>
                        <Button style={{backgroundColor: '#a8d5dc'}}
                                onClick={() => executeAllWidgets(visibleWidgets)}> Run all </Button>
                        <Button style={{backgroundColor: '#a8d5dc'}}
                                onClick={() => executeAutorunWidgets()}> Run checked </Button>
                    </span>}
                </h2>
            </div>
            <div style={{padding: 10, height: 'calc(100% - 25px)', overflow: 'auto'}}>
                {visibleWidgets && visibleWidgets.map((i, index) => (
                    <WidgetListItem key={index} executeWidget={executeWidget} setGlobalState={setGlobalState}
                                    setWidgetExecutionProgress={setWidgetExecutionProgress} onSubmit={onSubmit}
                                    globalState={globalState} widget={i} setShowSettings={setShowSettings}/>))}
                {(!visibleWidgets || visibleWidgets.length === 0) && <h2>No widgets for this type (yet)</h2>}
            </div>
        </div>)
}

export default Widgets;
