import React from "react";
import styled from "styled-components";
import WidgetItemInfo from "../../models/WidgetItemInfo";

export const WidgetWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.3rem 1rem;
  display: flex;
  user-select: text;

  div {
    line-height: 1.5;
    flex-grow: 1;
    max-width: 82%;
  }

  div:first-of-type {
    max-width: 18%;
    white-space: nowrap;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

`;

interface Context {
    widget: WidgetItemInfo,
    onSubmit: Function,
    typesInfo: Object
}

const WidgetBasicDetails = ({widget, onSubmit, typesInfo}: Context) => {
    return (
        <>
            <WidgetWrapper>
                <div>
                    Name
                </div>
                <div>{widget.name}</div>
            </WidgetWrapper>
            <WidgetWrapper>
                <div>
                    Description
                </div>
                <div dangerouslySetInnerHTML={{__html: widget.description}}></div>
            </WidgetWrapper>
            <WidgetWrapper>
                <div>
                    Sign-up URL
                </div>
                <a href={widget.signupURL} target='_blank'>{widget.signupURL}</a>
            </WidgetWrapper>
            <WidgetWrapper>
                <div>
                    Region
                </div>
                <div>{widget.region}</div>
            </WidgetWrapper>
            <WidgetWrapper>
                <div>
                    Usage
                </div>
                <div>{widget.payWall}</div>
            </WidgetWrapper>
            <WidgetWrapper>
                <div>
                    Types
                </div>
                <div>{widget.types.map(t => typesInfo[t] ? typesInfo[t].display : t).join(', ')}</div>
            </WidgetWrapper>
        </>
    )
}

export default WidgetBasicDetails;
