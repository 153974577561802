import {camelCase, last, startCase} from 'lodash';

/**
 * Format link, substituting in  entity values
 */

const subGroupPattern = /\{([A-z0-9-.]*):?([A-z0-9-.]*)\}/g;

function stripPhone(value: string) {
    return value.replace(/[() \-+]/gi, '-');
}

function spaceToDash(value: string) {
    return value.replace(/ /gi, '-');
}

function spaceToNothing(value: string) {
    return value.replace(/ /gi, '');
}

function firstName(value: string) {
    return value.split(' ')[0];
}

function splitByCommaAndTrim(value: string) {
    return value.split(',').map(a => a.trim());
}

function lastName(value: string) {
    return last(value.split(' ', 2));
}

function firstIP(value: string) {
    return value.split('/')[0];
}

const formatters = {
    splitByCommaAndTrim,
    spaceToNothing,
    stripPhone,
    spaceToDash,
    spaceToSlash: (value: string) => value.replace(/ /gi, '/'),
    domainFromEmail: (value: string) => last(value.split('@')),
    firstName,
    lastName,
    firstIP // First IP from CIDR
};

export function formatLink(link: string, value: string) {

    function getValueForKey(key: string) {
        if (key === 'value') {
            return value;
        }
        return '';
    }

    function subGroupReplacer(match: string, key: string, formatter: string) {
        let value = getValueForKey(key);
        if (formatter) {
            // format value
            if (formatters.hasOwnProperty(formatter)) {
                // @ts-ignore
                value = formatters[formatter](value);
            } else if (formatter === 'noEncoding' || formatter === 'noEndcoding') {
                return value;
            } else {
                console.log(`NEED TO IMPLEMENT FORMATTER: ${formatter}`);
            }
        }

        return encodeURIComponent(value);
    }

    return link.replace(subGroupPattern, subGroupReplacer);
}

export function nameToDisplay(name: string) {
    if (name.substr(0, 1).toLowerCase() !== name.substr(0, 1)) {
        // Assume capital first letter means that developer has already formatted name.
        return name;
    }

    return startCase(camelCase(name));
}

