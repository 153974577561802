import {Button, Col, Row, Select, Tooltip} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, {useEffect, useRef, useState} from 'react';
import GlobalComponentContext from "../../models/GlobalComponentContext";
import deduceType from "../../utils/InputTypeDeducer";
import {isNotEmpty} from "../../utils/ObjectUtils";


interface Context extends GlobalComponentContext {
    types: Set<string>,
    typesInfo: Object,
    debouncedSetSearchInputValue: Function
}

const ExecutionInputs = ({setGlobalState, globalState, types, typesInfo, debouncedSetSearchInputValue}: Context) => {

    const [internalValue, setInternalValue] = useState(globalState.searchInput);
    const textAreaRef = useRef();

    useEffect(() => {
        if (globalState.searchInputExternal && globalState.searchInputExternal !== internalValue) {
            setInternalValue(globalState.searchInputExternal);
            const type = globalState.searchInputExternal.split('\n').filter(s => s)
                .map(s => deduceType(s))
                .find(s => !!s)
            setGlobalState({
                ...globalState,
                searchInput: globalState.searchInputExternal,
                multilineSearch: globalState.searchInputExternal.includes('\n') && globalState.searchInputExternal.split('\n').filter(s => s).length > 1,
                searchInputExternal: '',
                filters: {...globalState.filters, type: globalState.filters.imposedType || type || 'any'},
                manualTypeChange: false
            });
        } else if (isNotEmpty(globalState.searchInput)) {
            const type = globalState.searchInput.split('\n').filter(s => s)
                .map(s => deduceType(s))
                .find(s => !!s)
            setGlobalState({
                ...globalState,
                multilineSearch: globalState.searchInput.includes('\n') && globalState.searchInput.split('\n').filter(s => s).length > 1,
                filters: {...globalState.filters, type: globalState.filters.imposedType || type || new URLSearchParams(window.location.search).get('type') || 'any'},
                manualTypeChange: false
            });
            if(globalState.searchInput !== internalValue) {
                setInternalValue(globalState.searchInput)
            }
        }
    }, [globalState.searchInputExternal, globalState.searchInput, globalState.filters.imposedType]);

    useEffect(() => {
        if (types && globalState.filters.type && !types.has(globalState.filters.type)) {
            const type = deduceType(globalState.searchInput)
            setGlobalState({...globalState, filters: {...globalState.filters, type: type || globalState.filters.type}})
        }
    }, [globalState.filters.type, types])

    const onChange = (e: any) => {
        setInternalValue(e.target.value);
        debouncedSetSearchInputValue(e.target.value)
    }
    return (
        <Row style={{paddingBottom: '10px'}}>
            <Col span={18} style={{paddingLeft: 15}}>
                <div style={{display: 'flex'}}>
                    <h2>Input</h2>
                    <div style={{flex: 1}}>
                        <Tooltip title="Copy input">
                            <Button type="text"
                                    onClick={() => {
                                        navigator.clipboard.writeText(globalState.searchInput);
                                    }}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                               width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path
                                            d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                                    </svg>}/>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="Clear input">
                            <Button type="text"
                                    style={{paddingRight: '5px'}}
                                    onClick={() => {
                                        onChange({target: {value: ''}})
                                    }}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                               width="24px" fill="#000000">
                                        <path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path
                                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
                                    </svg>}/>
                        </Tooltip>
                    </div>
                </div>
                {globalState.filters.type !== 'image' && <TextArea className="ant-input-xl" value={internalValue} defaultValue={internalValue}
                           placeholder="Run against..." rows={4}
                           ref={textAreaRef}
                           onChange={onChange}/>}
                {globalState.filters.type === 'image' && <TextArea className="ant-input-xl" disabled
                                                                   defaultValue="Only available in extension context menu" rows={4} />}
            </Col>
            <Col span={6} style={{paddingRight: '10px'}}>
                <h2>Type</h2>
                <Select key={globalState.filters.type} className="ant-input-xl" value={globalState.filters.type}
                        style={{width: '98%'}}
                        onChange={(value) => {
                            if (setGlobalState) {
                                setGlobalState({...globalState, filters: {...globalState.filters, type: value}, manualTypeChange: true})
                            }
                        }}>
                    {types && [...types].map(c => (<Select.Option value={c}>{typesInfo[c]?.display || c}</Select.Option>))}
                </Select>
            </Col>
        </Row>)
}

export default ExecutionInputs;
