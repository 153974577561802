import {sendNewWidgetsConfig, sendWidgetSettingsConfiguration} from "../App";

export default function onSubmitWidgetChanges(setGlobalState, globalState, widgets, widgetId, field, autorun, types = [], skipAutorunUpdate = false) {
    const configuration = widgets.items.map(w => {
        if (w.id === widgetId) {
            return {
                name: w.name,
                autorun: autorun,
                icon: w.icon,
                display: w.display,
                id: w.id
            }
        }
        return {
            name: w.name, icon: w.icon, autorun: w.autorun,display: w.display, id: w.id
        };
    });
    const updatedConfiguration = {...globalState.widgetAutorunConfig};
    if (configuration && !skipAutorunUpdate) {
        updatedConfiguration[globalState.filters.type] = [...configuration.filter(w => w.autorun)];
        if (types.length > 0) {
            types.forEach(t => updatedConfiguration[t] = [...configuration.filter(w => w.autorun)]);
        }
    }
    localStorage.setItem('WIDGET_AUTORUN_SETTINGS', JSON.stringify(updatedConfiguration));
    sendNewWidgetsConfig(updatedConfiguration);
    if (setGlobalState) {
        setGlobalState({...globalState, widgetAutorunConfig: updatedConfiguration});
    }
};


export function onSubmitWidgetConfigurationChanges(setGlobalState, globalState, widgets, widgetId, field) {
    const configuration = widgets.items.map(w => {
        if (w.id === widgetId) {
            const updateWidget = {
                name: w.name,
                fields: w.fields.map(f => {
                    if (f.key === field.key) {
                        return {name: f.name, key: f.key, value: field.value};
                    }
                    return {name: f.name, key: f.key, value: f.value};
                })
            };

            if (updateWidget.fields.filter(f => !f.value).length === 0) {
                onSubmitWidgetChanges(setGlobalState, globalState, widgets, widgetId, [], false, w.types, true);
            }

            return updateWidget
        }
        return {
            name: w.name, fields: w.fields.map(f => ({name: f.name, key: f.key, value: f.value}))
        };
    });
    if (configuration) {
        localStorage.setItem('WIDGET_SETTINGS', JSON.stringify(configuration));
        sendWidgetSettingsConfiguration(configuration);
        if (setGlobalState) {
            setGlobalState({...globalState, widgetConfiguration: configuration});
        }
    }
};

