import WidgetExecutionOutput from "../../../models/WidgetExecutionOutput";
import React from "react";
import {Col, Row} from "antd";
import styled from "styled-components";
import GlobalComponentContext from "../../../models/GlobalComponentContext";
import {BASE_URL} from "../../../App";
import deduceType from "../../../utils/InputTypeDeducer";
import {DisplayModes} from "../../MainView";

interface Context extends GlobalComponentContext {
    data: WidgetExecutionOutput
    children: any
}

const SectionTitleWrapper = styled.h2`
  font-size: 1.1rem !important;
  padding: 0 5px !important;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: none;
  border-right: none;
`;

const Section = ({data, children, globalState, setGlobalState}: Context) => {

    const onClick = () => {
        if (data.props.titleType) {
            if (DisplayModes.SIMPLIFIED === globalState.displayMode) {
                window.open(`${BASE_URL}?input=${data.props.name}&type=${data.props.titleType || deduceType(data.props.name)}`, '_blank')
                return;
            }
            setGlobalState({
                ...globalState,
                searchInputExternal: data.props.name,
                filters: {...globalState.filters, imposedType: data.props.titleType}
            });
        }
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <SectionTitleWrapper style={{ cursor: data.props.titleType ? 'pointer' : '' , backgroundColor: data.props.name === 'Input' ? '#71afac' : '#afafaf' }}
                                         onClick={onClick}>
                            {data.props.name}
                    </SectionTitleWrapper>
                </Col>
            </Row>
            {children}
        </div>
    );
}


export default Section;
